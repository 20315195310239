import ImgurService from '../../hosts/imgur.js';

import Image from './image.js';

class Resources {

  constructor({ app, connection }) {

    this.app = app;
    this.connection = connection;

    this.hosts = null;
    this.targetHost = null;
    this.list = [];

    this.images = [];

  }

  async getHostConfig() {
    try {
      const res = await this.connection.root.get('/config/hosts');

      this.hosts = res.data;
      this.app.config.globalProperties.$store.hosts = res.data;


      for (const host of this.hosts) {
        if (host.host == 'imgur') {
          host.service = new ImgurService(host);
        }
      }

      this.targetHost = this.hosts.find((h) => h.upload);

    } catch (err) {
      console.error('Could not load host config');
    }
  }

  async fetch() {
    if (!this.hosts) {
      await this.getHostConfig();
    }

    const res = await this.connection.world.get(`/r`);

    this.list = res.data;
    this.app.config.globalProperties.$store.resources = this.list;

    this.images = []; // When data is better structured this won't be necessary. For now we make fetching a bit heavier to avoid bugs

  }

  getImage({ id, handle }) {
    const image = this.images.find((i) => i.match({ id, handle }));
    if (image) {
      return image.data;
    }
    const resource = this.list.find((resource) => {
      if (id) {
        return resource.id == id
      } else {
        return handle && resource.handle == handle
      }
    });

    if (!resource) {
      return null;
    }

    const new_image = new Image(resource, {
      app: this.app,
      connection: this.connection,
      hosts: this.hosts
    });

    this.images.push(new_image);

    new_image.fetch().catch(this.refreshError);

    return new_image.data;
  }

  async uploadImage(data) {

    if (!this.targetHost) {
      throw {
        message: "This instance of Artefact does not have a configured image host. Image upload is unavailable."
      };
    }

    const artefact_data = {
      name: data.name,
      handle: data.handle,
      host: this.targetHost.host,
      hostName: this.targetHost.name
    }

    if (this.targetHost.host == 'imgur') {
      const res = await this.targetHost.service.uploadImage(data);
      artefact_data.host_id = res.data.id;
      artefact_data.host_deletehash = res.data.deletehash;
    }

    const res = await this.connection.world.post('/r/', artefact_data);

    this.list.push(res.data);

    await this.fetch() // TODO : not do this and handle data properly

  }

  async updateImage(id, data) {
    await this.connection.world.put(`/r/${id}`, data);
    await this.fetch();

    // TODO : lighten this opperation by immitating the way fragments are structured
  }

  async deleteImage(id) {
    const res = await this.connection.world.delete(`/r/${id}`);

    const host = this.hosts.find((h) => h.name == res.data.hostName);

    if (host.host == 'imgur') {
      await host.service.deleteImage(res.data.host_deletehash);
    }

    await this.fetch(); // TODO : not do this and handle data properly

  }
}

export default Resources;