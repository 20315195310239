import { reactive } from 'vue';

class Article {

  constructor(fragment, { app, connection }) {
    this.app = app;
    this.connection = connection;

    if (fragment.content != 'Article') {
      console.error('Article must recieve an article fragment as an argument');
      throw {}
    }

    this.fetchedAt = null;

    this.data = reactive({
      id: fragment.id,
      name: fragment.name,
      props: fragment.props,
      handles: fragment.handles,
      types: fragment.types,
      content: fragment.content,
      createdAt: fragment.createdAt,
      updatedAt: fragment.updatedAt,
      article: {
        text: fragment.article?.text || ""
      }
    });

  }

  match({ id, handle }) {
    if (typeof id == 'number') {
      return this.data.id == id;
    } else {
      return handle && this.data.handles.indexOf(handle) != -1;
    }
  }

  async fetch() {
    if (this.data.id == -1) {
      return;
    }
    const res = await this.connection.world.get(
      `/a/${this.data.id}`
    );

    this.fetchedAt = Date.now();

    this.data.name = res.data.name;
    this.data.props = res.data.props;
    this.data.handles = res.data.handles;
    this.data.types = res.data.types;
    this.data.updatedAt = res.data.updatedAt;
    this.data.article.text = res.data.article.text;
  }

  async update(data) {
    const res = await this.connection.world.put(
      `/a/${this.data.id}`, data
    );

    this.fetchedAt = Date.now();

    this.data.name = res.data.name;
    this.data.props = res.data.props;
    this.data.handles = res.data.handles;
    this.data.types = res.data.types;
    this.data.updatedAt = res.data.updatedAt;
    this.data.article.text = res.data.article.text;
  }

}

export default Article;