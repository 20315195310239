<template>
  <div class="field is-grouped">
    <div class="control" v-for="(element, index) in elements" :key="index">
      <div class="buttons has-addons">
        <button class="button">{{ displayed(element) }}</button>
        <button class="button" @click="$emit('remove', element)">
          <span class="icon is-small">
            <i class="fas fa-times"></i>
          </span>
        </button>
      </div>
    </div>
    <div class="control">
      <slot>
        <button class="button" @click="$emit('add')">Add</button>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    elements: Array,
    displayed: Function,
  },
};
</script>
