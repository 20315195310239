import { createApp } from 'vue'
import App from './App.vue'
// import Buefy from 'buefy'
import router from './router/index'
// import world from './plugins/world.old'
import log from './plugins/log'
import safeguard from './plugins/safeguard'
import session from './plugins/session'
import service from './services/plugin'
import markdown from './plugins/markdown'
import hanldes from './plugins/handles'

import '../style/main.scss'

createApp(App)
  // .use(Buefy)
  .use(log)
  .use(session)
  .use(service)
  .use(safeguard)
  .use(router)
  .use(markdown)
  .use(hanldes)
  .mount('#app')
