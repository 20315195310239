class WorldService {

  constructor(mainframe) {
    this.connection = mainframe.connection;
  }


  getAll() {
    return this.connection.root.get("/u");
  }

  get(id) {
    return this.connection.root.get(`/u/${id}`);
  }

  update(id, data) {
    return this.connection.root.put(`/u/${id}`, data);
  }

}

export default WorldService;
