<template>
  <nav class="panel is-info">
    <p class="panel-heading">Settings</p>
    <div v-for="link in links" :key="link">
      <router-link :to="link.to">
        <a class="panel-block" :class="{ 'is-active': selected(link) }">
          <span class="panel-icon">
            <i :class="link.icon" aria-hidden="true"></i>
          </span>
          <b v-if="selected(link)">{{ link.text }}</b>
          <span v-else> {{ link.text }} </span>
        </a>
      </router-link>
    </div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      links: [
        {
          to: { name: "settings-general" },
          text: "General",
          icon: "fas fa-globe",
        },
        {
          to: { name: "settings-members" },
          text: "Members",
          icon: "fas fa-users",
        },
        {
          to: { name: "settings-layout" },
          text: "Layout",
          icon: "fas fa-layer-group",
          // icon: "fas fa-puzzle-piece",
        },
        {
          to: { name: "settings-danger" },
          text: "Danger Zone!",
          icon: "fas fa-exclamation-triangle",
        },
      ],
    };
  },
  methods: {
    selected(item) {
      return this.$route.name == item.to.name;
    },
  },
};
</script>
