<template>
  <div>
    <div class="level">
      <div class="level-left">
        <div class="level-item">
          <h1 class="title">
            {{ dashboard?.name }}
          </h1>
        </div>
      </div>
      <div class="level-right">
        <p class="level-item">
          <router-link
            class="button"
            v-if="$world.canWrite"
            :to="{ name: 'dashboard-edit' }"
          >
            <span class="icon is-small">
              <i class="fas fa-edit"></i>
            </span>
            <span> Edit </span>
          </router-link>
          <button
            class="button ml-3"
            v-if="$world.canWrite"
            @click="$refs.confmodal.open()"
          >
            <span class="icon is-small">
              <i class="fas fa-trash-alt"></i>
            </span>
            <span> Delete </span>
          </button>
        </p>
      </div>
    </div>
    <div class="level">
      <div class="level-left">
        <p class="level-item">Types :</p>
        <div v-for="type in typeNames" :key="type">
          <em class="level-item ml-3">{{ type }}</em>
        </div>
      </div>
    </div>
    <div class="level">
      <div class="level-left">
        <p class="level-item">Handles :</p>
        <div v-for="handle in handles" :key="handle">
          <em class="level-item ml-3">{{ handle }}</em>
        </div>
      </div>
    </div>

    <hr class="hr" />
    <div class="columns is-mobile is-centered is-multiline">
      <div class="column is-full-touch">
        <h2 class="subtitle">
          Welcome to the dashboard placeholder. Dashboards haven't been
          implemented yet.
        </h2>
        <div class="ml-2 mt-2 mr-2">
          {{ dashboard?.dashboard.tempContent }}
        </div>
      </div>

      <div class="column is-narrow" style="width: 320px">
        <!-- <div class="column is-full-tablet is-narrow-desktop is-narrow-fullhd"> -->
        <div class="box">
          <div
            :class="{ 'mt-5': index != 0 }"
            v-for="(section, index) in propsDisplay"
            :key="section"
          >
            <h5 class="title is-5 table-title" style="margin-bottom: 8px">
              {{ section.name }}
            </h5>
            <table class="table is-fullwidth is-hoverable is-narrow">
              <tbody>
                <tr v-for="entry in section.content" :key="entry">
                  <th>{{ entry.key }}</th>
                  <td>{{ entry.value }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <confirmation-modal ref="confmodal" @confirm="deleteConfirm">
      Are you sure you want to delete this dashboard ? This operation cannot be
      reversed.
    </confirmation-modal>
  </div>
</template>


<script>
import ConfirmationModal from "@/components/templates/ConfirmationModal.vue";

export default {
  components: { ConfirmationModal },
  data() {
    return {
      dashboard: null,
      types: [],
    };
  },
  computed: {
    propsDisplay() {
      if (!this.dashboard?.props) {
        return [];
      }
      const temp = {};
      for (const key in this.dashboard.props) {
        if (this.dashboard.props[key].value == "") {
          continue;
        }
        const section = this.dashboard.props[key].section || "";
        if (!temp[section]) {
          temp[section] = {};
        }
        temp[section][key] = this.dashboard.props[key];
      }
      const res = [];
      for (const section in temp) {
        const o = {
          name: section,
          index: 0,
          content: [],
        };
        for (const key in temp[section]) {
          o.content.push({
            key: key,
            value: temp[section][key].value,
            index: temp[section][key].index,
          });
          o.index = Math.max(o.index, temp[section][key].index);
        }
        o.content.sort((e1, e2) => e2.index < e1.index);
        res.push(o);
      }
      res.sort((e1, e2) => e2.index < e1.index);
      return res;
    },
    typeNames() {
      if (!this.dashboard || this.types.length == 0) {
        return [];
      }
      const result = [];
      for (const t of this.dashboard.types) {
        const type = this.types.find((type) => type.id == t.typeId);
        if (type) {
          result.push(type.name);
        } else {
          result.push("???");
        }
      }
      return result;
    },
    handles() {
      if (this.dashboard) {
        return this.dashboard.handles.filter((h) => this.$handles.verify(h));
      } else {
        return [];
      }
    },
  },
  methods: {
    async getDashboard() {
      try {
        var response = await this.$service.dashboard.get(
          this.$route.params.handle
        );
        this.dashboard = response.data;
      } catch (e) {
        this.$router.push({
          name: "fragment-not-found",
          hanlde: this.$route.params.handle,
        });
      }
    },
    async getTypes() {
      try {
        const response = await this.$service.type.getAll();
        this.types = response.data;
      } catch (err) {
        // skip
      }
    },
    async deleteConfirm() {
      try {
        await this.$service.dashboard.deleteOne(this.dashboard.id);
        this.$emit("refresh");
        this.$router.push({ name: "fragment-explorer" });
      } catch (err) {
        // skip
      }
    },
  },
  updated() {
    if (this.$route.params.handle != this.dashboard?.id) {
      this.getDashboard();
    }
  },
  async mounted() {
    await this.getDashboard();
    await this.getTypes();
  },
};
</script>

<style scoped>
.table {
  table-layout: fixed;
}
</style>
