import { reactive } from 'vue';

class Image {

  constructor(resource, { app, connection, hosts }) {

    this.resource = resource;

    this.app = app;
    this.connection = connection;

    this.resource = resource;

    this.host = hosts.find(
      (h) => h.name == resource.hostName
    );

    this.data = reactive({
      id: resource.id,
      name: resource.name,
      handle: resource.handle,
      createdAt: resource.createdAt,
      updatedAt: resource.updatedAt,

      url: ""
    });

  }

  match({ id, handle }) {
    if (id) {
      return this.data.id == id
    } else {
      return handle && this.data.handle == handle
    }
  }

  async fetch() {

    if (this.host.host == 'imgur') {
      const res = await this.host.service.getImage(this.resource.host_id);
      this.data.url = res.data.link;
    }
  }

}

export default Image;