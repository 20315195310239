<template>
  <div class="modal" :class="{ 'is-active': active }">
    <div class="modal-background" @click="close"></div>
    <div class="modal-card card">
      <header class="modal-card-head">
        <h1 class="modal-card-title">Choose a file to upload</h1>
        <button class="delete" @click="close"></button>
      </header>
      <section clas="modal-card-body">
        <div class="field ml-5 mr-5 mt-5">
          <label class="label">Image</label>
          <div class="control">
            <input type="file" @change="fileChanged" />
          </div>
        </div>

        <div class="field ml-5 mr-5 mt-5">
          <label class="label">Name</label>
          <div class="control">
            <input class="input" v-model="name" placeholder="name" rows="3" />
          </div>
        </div>

        <div class="field ml-5 mr-5 mt-5">
          <label class="label">
            Handle
            <help-box>
              The handle is how you include this image in an article. In any
              article, you can use the syntax ![](handle) to include this image
              (replace 'handle' by whatever handle you've chosen).
            </help-box>
          </label>
          <div class="control">
            <input
              class="input"
              v-model="handle"
              placeholder="handle"
              @input="handleChanged = true"
              rows="3"
            />
          </div>
        </div>

        <div v-if="errorMessage" class="field ml-5 mr-5 mt-5 has-text-danger">
          {{ errorMessage }}
        </div>

        <section class="section">
          <div class="level">
            <div class="level-right">
              <a class="level-right button" @click="confirm">Confirm</a>
            </div>
          </div>
        </section>
      </section>
    </div>
  </div>
</template>

<script>
import HelpBox from "../../templates/HelpBox.vue";
const supportedTypes = ["image/jpeg", "image/png"];

export default {
  name: "image-upload-modal",
  components: { HelpBox },
  data() {
    return {
      name: "",
      handle: "",
      handleChanged: false,
      file: null,

      active: false,
      errorMessage: null,
    };
  },
  watch: {
    name(newValue) {
      if (!this.handleChanged) {
        this.handle = this.$handles.purify(newValue);
      }
    },
  },
  methods: {
    open() {
      this.active = true;
      this.name = "";
      this.handle = "";
      this.handleChanged = false;
      this.file = null;
    },
    close() {
      this.active = false;
    },
    async confirm() {
      if (!this.file) {
        this.errorMessage = "You must choose a file to upload";
        return;
      }

      if (supportedTypes.indexOf(this.file.type) == -1) {
        this.errorMessage = "The only supported types are jpeg and png";
        return;
      }

      const data = {
        image: this.file,
        name: this.name,
        handle: this.handle,
      };

      try {
        await this.$service.resource.uploadImage(data);
        await this.$emit("uploaded", data);
        this.active = false;
      } catch (err) {
        this.errorMessage = err.message;
      }
    },
    fileChanged(event) {
      this.file = event.target.files[0];
      this.name = this.trimExtension(this.file.name);
    },
    trimExtension(str) {
      var i = 0;
      for (const j in str) {
        if (str[j] == ".") {
          i = j;
        }
      }
      return str.substr(0, i);
    },
  },
};
</script>