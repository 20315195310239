<template>
  <div class="modal" :class="{ 'is-active': active }">
    <div class="modal-background" @click="cancel"></div>
    <div class="modal-card card">
      <section class="modal-card-body">
        <h1 class="title">Choose a user</h1>
        <div>
          <span v-if="!userList">Loading</span>
          <span v-if="userList">
            <div class="field" v-for="(user, index) in userList" :key="index">
              <button
                class="control button is-fullwidth"
                @click="selection = index"
                :class="{
                  'is-dark': selection != index,
                  'is-light': selection == index,
                }"
              >
                <div style="margin-right: auto">
                  {{ user.username }}
                </div>
              </button>
            </div>
          </span>
        </div>
      </section>
      <footer class="card-footer">
        <a class="card-footer-item is-warning" @click="confirm"> Confirm </a>
        <a class="card-footer-item" @click="cancel">Cancel</a>
      </footer>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      selection: -1,
    };
  },
  props: ["active", "userList"],
  methods: {
    confirm() {
      this.$emit("confirm", this.selection);
    },
    cancel() {
      this.$emit("cancel");
    },
  },
  watch: {
    active(newValue) {
      if (newValue) {
        this.selection = -1;
      }
    },
  },
};
</script>
