import Fragments from './fragments/fragments';
import Resources from './resources/resources';
import Settings from './settings/settings';

const refreshInterval = 60000; // 1 minute

class WorldData {

  constructor(handle) {

    this.name = null;
    this.id = null;
    this.handle = handle;
    this.description = null;
    this.isPrivate = true;
    this.ownerId = null;
    this.createdAt = null;
    this.updatedAt = null;
    this.settingsUpdatedAt = null;
    this.fragmentsUpdatedAt = null;
    this.resourcesUpdatedAt = null;

    this.writers = [];
    this.viewers = [];

    this.isOwner = false;
    this.isWriter = false;
    this.canWrite = false;
  }

  update(data, userId) {

    this.name = data.name;
    this.id = data.id;
    this.description = data.description;
    this.isPrivate = data.isPrivate;
    this.ownerId = this.ownerId = data.ownerId;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
    this.settingsUpdatedAt = data.settingsUpdatedAt;
    this.fragmentsUpdatedAt = data.fragmentsUpdatedAt;
    this.resourcesUpdatedAt = data.resourcesUpdatedAt;


    this.writers = [];
    for (const wk of data.writeKeys) {
      this.writers.push(wk.userId);
    }

    this.viewers = [];
    for (const vk of data.viewKeys) {
      this.viewers.push(vk.userId);
    }

    if (userId) {
      this.isOwner = data.ownerId == userId;
      this.isWriter = this.writers.indexOf(userId) != -1;
    } else {
      this.isOwner = false;
      this.isWriter = false;
    }
    this.canWrite = this.isOwner || this.isWriter;
  }

}

class World {

  constructor({ handle, app, connection }) {
    this.handle = handle;
    this.app = app;
    this.connection = connection;

    this.data = new WorldData(handle);

    app.config.globalProperties.$store.world = this.data;
    app.config.globalProperties.$world.update(this.data, app.config.globalProperties.$session.user.id);

    this.fragments = new Fragments({ handle, app, connection });
    this.settings = new Settings({ handle, app, connection });
    this.resources = new Resources({ handle, app, connection });

    this.fetchedAt = null;

  }

  needsRefresh() {
    if (!this.fetchedAt) {
      return true;
    }
    return (this.fetchedAt + refreshInterval < Date.now());
  }

  get() {
    if (this.needsRefresh()) {
      this.fetch().catch(this.refreshError);
    }
    return this;
  }

  async fetch() {
    const res = await this.connection.root.get(`/worlds/handle/${this.handle}`);
    if (!this.fetchedAt || res.data.updatedAt != this.data.updatedAt) {
      if (res.data.settingsUpdatedAt != this.data.settingsUpdatedAt) {
        await this.settings.fetch();
      }
      if (res.data.fragmentsUpdatedAt != this.data.fragmentsUpdatedAt) {
        await this.fragments.fetch();
      }
      if (res.data.resourcesUpdatedAt != this.data.resourcesUpdatedAt) {
        await this.resources.fetch();
      }
      this.data.update(res.data, this.app.config.globalProperties.$session.user.id);
      this.app.config.globalProperties.$store.world = this.data;
      this.app.config.globalProperties.$world.update(this.data);
    }
    this.fetchedAt = Date.now();

  }

  refreshError(err) {
    console.error('Error encountered while refreshing world');
    console.error(err);
  }
}

export default World;