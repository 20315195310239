<template>
  <div class="container is-max-widescreen">
    <h1 class="title">Register</h1>

    <div class="field">
      <label class="label">Username</label>
      <div class="control has-icons-left">
        <input
          id="username-input"
          class="input"
          type="text"
          name="username"
          v-model="username"
          placeholder="username"
          @keypress="forward"
        />
        <span class="icon is-small is-left">
          <i class="fas fa-user"></i>
        </span>
      </div>
    </div>

    <div class="field">
      <label class="label">Password</label>
      <div class="control has-icons-left">
        <input
          id="password-input"
          :class="'input' + passwordIsDanger"
          type="password"
          name="password"
          v-model="password"
          placeholder="password"
          @keypress="forward"
        />
        <span class="icon is-small is-left">
          <i class="fas fa-lock"></i>
        </span>
      </div>
    </div>

    <div class="field">
      <div class="control has-icons-left">
        <input
          id="password-confirm-input"
          :class="'input' + passwordIsDanger"
          type="password"
          name="passwordConfirmation"
          v-model="passwordConfirmation"
          placeholder="confirm password"
          @keypress="forward"
        />
        <span class="icon is-small is-left">
          <i class="fas fa-lock"></i>
        </span>
        <p v-if="passwordDontMatch" class="help is-danger">
          Passwords don't match
        </p>
      </div>
    </div>

    <div class="field is-grouped">
      <div class="control">
        <button class="button is-link" @click="submit">Register</button>
      </div>
      <div class="control mt-2">
        <router-link class="" to="/login">Login</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Register",
  data() {
    return {
      username: "",
      password: "",
      passwordConfirmation: "",
    };
  },
  computed: {
    passwordDontMatch() {
      return (
        this.password != this.passwordConfirmation &&
        this.password.length > 0 &&
        this.passwordConfirmation.length > 0
      );
    },
    passwordIsDanger() {
      return this.passwordDontMatch ? " is-danger" : "";
    },
  },
  methods: {
    forward(event) {
      if (event.key == "Enter") {
        if (event.originalTarget.id == "username-input") {
          document.getElementById("password-input").focus();
        }
        if (event.originalTarget.id == "password-input") {
          document.getElementById("password-confirm-input").focus();
        }
        if (event.originalTarget.id == "password-confirm-input") {
          this.submit();
        }
      }
    },
    async submit() {
      if (this.password != this.passwordConfirmation) {
        return;
      }
      try {
        await this.$service.auth.register({
          username: this.username,
          password: this.password,
        });
      } catch (e) {
        return;
      }
      this.$router.push("/login");
    },
  },
};
</script>
