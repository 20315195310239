<template>
  <a class="panel-block" :class="depthClass">
    <span class="panel-icon" v-if="!node.open">
      <i class="fas fa-caret-right"></i>
    </span>
    <span class="panel-icon" v-if="node.open">
      <i class="fas fa-caret-down"></i>
    </span>
    <span class="panel-icon">
      <i class="fas fa-cubes"></i>
    </span>
    {{ node.type.name }}
  </a>
</template>

<script>
export default {
  props: ["node", "depth"],
  computed: {
    depthClass() {
      const res = {
        "ml-2": this.depth == 2,
        "ml-4": this.depth > 2,
      };
      return res;
    },
  },
};
</script>