import { defineComponent, h } from 'vue';
import SPFolderLine from "./SPFolderLine.vue";
import SPFileLine from "./SPFileLine.vue";

const ROOT = 'root';
const FOLDER = 'folder';
const FRAGMENT = 'fragment';

function walk(node, depth) {

  if (node.hidden) {
    return h('DIV');
  }

  if (node.is == FRAGMENT) {
    return h(SPFileLine, {
      'node': node,
      'depth': depth,
    });
  }

  const children = [];

  if (node.is == FOLDER) {
    children.push(h(SPFolderLine, {
      'node': node,
      'depth': depth,
      'onClick': () => {
        node.open = !node.open
      }
    }));
  }

  if (node.is == ROOT || (node.is == FOLDER && node.open)) {
    for (const child of node.children) {
      children.push(walk(child, depth + 1));
    }
  }
  if (node.is == FOLDER && node.open) {
    // Add a black line at the end of the folder
    // There will be a cleaner way to do this when we have custom css
    const lineOptions = {
      style: "background-color:#CCCCCC; height:1px;"
    }
    if (depth == 1) {
      lineOptions.class = "ml-2"
    }
    if (depth > 1) {
      lineOptions.class = "ml-4"
    }
    children.push(h('DIV', lineOptions, []));
  }


  return h('DIV', {}, children);

}

function matchText(subStr, superStr) {
  const a = subStr.toLowerCase().split("");
  const b = superStr.toLowerCase().split("");

  var ai = 0;
  var bi = 0;

  while (bi < b.length) {
    if (b[bi] == a[ai]) {
      ai++;
      bi++;
    } else {
      bi++;
    }
    if (ai >= a.length) {
      return true;
    }
  }
  return false;
}

function match(search, fragment) {
  if (search == "") {
    return true;
  }
  const toMatch = [fragment.name].concat(fragment.handles);
  for (const m of toMatch) {
    if (matchText(search, m)) {
      return true;
    }
  }
  return false;
}

export default defineComponent({
  name: 'fragment-navigation',
  components: {
    SPFolderLine,
    SPFileLine,
  },
  data() {
    return {
      display: {},
    };
  },
  computed: {
    tree() {

      if (this.$store.updateKey < 0) {
        throw {}
        // cause this to be triggered by an updateKey change
      }

      const fragments = this.$store.fragments;
      const types = this.$store.types;

      const root = {
        is: ROOT,
        children: [],
      };

      const nodeList = [];

      for (const t of types) {
        const node = {
          is: FOLDER,
          type: t,
          childrenIds: t.children.map((prt) => prt.childId),
        };
        nodeList.push(node);
        if (t.parents.length == 0) {
          root.children.push(node);
        }
      }

      for (const node of nodeList) {
        node.children = node.childrenIds.map((id) => {
          return nodeList.find((n2) => n2.type.id == id);
        });
      }

      for (const a of fragments) {
        const node = {
          is: FRAGMENT,
          fragment: a,
        };
        for (const btt of a.types) {
          const fnode = nodeList.find((n) => (
            n.type.id == btt.typeId
          ));
          if (fnode) {
            fnode.children.push(node);
          }
        }
        if (a.types.length == 0) {
          root.children.push(node);
        }
      }

      return root;
    }
  },
  methods: {
    generateDisplay() {

      function makeDisplay(node) {
        const displayNode = {
          is: node.is,
          open: (node.is == ROOT),
          hidden: false,
          children: node.children?.map((n) => makeDisplay(n))
        };

        if (node.is == FOLDER) {
          displayNode.type = node.type;
        }

        if (node.is == FRAGMENT) {
          displayNode.fragment = node.fragment;
        }

        return displayNode;
      }

      this.display = makeDisplay(this.tree);

    },
    filter(search) {

      function show_all(node) {
        node.hidden = false;
        if (node.is != FRAGMENT) {
          for (const child of node.children) {
            show_all(child);
          }
        }
      }

      if (search == "") {
        show_all(this.display);
        return;
      }

      function browse_and_hide(node) {
        node.hidden = true;

        if (node.is == FRAGMENT) {
          if (match(search, node.fragment)) {
            node.hidden = false;
          }
        } else {
          for (const child of node.children) {
            if (browse_and_hide(child)) {
              node.hidden = false;
              node.open = true;
            }
          }
        }

        return !node.hidden;
      }

      browse_and_hide(this.display);

    },
    openCurrent() {
      // Make sure that a link to the current fragment is visible in the navigator

      const route_fragment = this.$route.params.handle;
      if (!route_fragment) {
        return;
      }

      function search_and_open(node) {
        if (node.is == FRAGMENT) {
          if (node.fragment.handles.find((h) => h == route_fragment)) {
            return true;
          }
        } else {
          for (const cnode of node.children) {
            const res = search_and_open(cnode);
            if (res) {
              node.open = true;
              return true;
            }
          }
        }
        return false;
      }

      search_and_open(this.display);
    }
  },
  render() {
    if (this.display.hidden) {
      return h('EM', { class: 'panel-block' }, "No result found");
    } else {
      return walk(this.display, 0);
    }
  },
  watch: {
    tree() {
      this.generateDisplay();
      this.openCurrent();
    },
    $route(newVal) {
      const handle = newVal.params.handle;
      if (typeof handle == 'string' && !this.$handles.isNumber(handle)) {
        /* If an fragment is accessed from an in-fragment link, it should be 
        refered to with a handle and not with it's id. We don't want to run
        openCurrent if the fragment was accessed through the navigator
        */
        this.openCurrent();
      }
    }
  },
  mounted() {
    this.generateDisplay();
    this.openCurrent();
  },
});