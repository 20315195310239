
class Members {

  constructor({ app, connection }) {

    this.app = app;
    this.connection = connection;

    this.writers = [];
    this.viewers = [];

  }

  async fetch() {
    const res = await this.connection.world.get(`/m`);

    this.writers = res.data.writers;
    this.viewers = res.data.viewers;
  }

}

export default Members;