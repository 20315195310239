<template>
  <router-link class="panel-block" :class="depthClass" :to="link">
    <span class="panel-icon">
      <i class="fas fa-cube"></i>
    </span>
    <b v-if="isActive">{{ node.fragment.name }}</b>
    <span v-else> {{ node.fragment.name }} </span>
  </router-link>
</template>

<script>
export default {
  props: ["node", "depth"],
  computed: {
    depthClass() {
      const res = {
        "ml-2": this.depth == 2,
        "ml-4": this.depth > 2,
        "is-active": this.isActive,
      };
      return res;
    },
    isActive() {
      const route_fragment = this.$route.params.handle;
      return Boolean(
        this.node.fragment.handles.find((handle) => {
          return handle == route_fragment;
        })
      );
    },
    link() {
      if (this.node.fragment.content == "Article") {
        return {
          name: "article",
          params: { handle: this.node.fragment.id },
        };
      }
      if (this.node.fragment.content == "Dashboard") {
        return {
          name: "dashboard",
          params: { handle: this.node.fragment.id },
        };
      }
      return {
        name: "not-found",
        params: { handle: this.node.fragment.id },
      };
    },
  },
};
</script>