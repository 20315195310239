
class Layout {

  constructor({ app, connection }) {

    this.app = app;
    this.connection = connection;

    this.types = [];

  }

  async fetch() {
    const res = await this.connection.world.get(`/t`);

    this.types = res.data;
    this.app.config.globalProperties.$store.types = this.types;

  }

  async createType(data) {
    const res = await this.connection.world.post('/t/', data);
    this.types.push(res.data);
    return res.data;
  }

  async updateType(id, data) {
    await this.connection.world.put(`/t/u/${id}`, data);
    await this.fetch();
    // TODO remove fetch and use first request result
  }

  async updateParenthood(data) {
    await this.connection.world.put(`/t/p`, data);
    await this.fetch();
  }

  async deleteType(id) {
    await this.connection.world.delete(`/t/${id}`);
    await this.fetch();
  }


}

export default Layout;