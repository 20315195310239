
class DashboardService {

  constructor(mainframe) {
    this.mainframe = mainframe;
  }

  get(id) {
    return this.mainframe.getWorld().fragments.getDashboard({
      id: id
    });
  }

  async fetch(id) {
    return this.mainframe.getWorld().fragments.fetchDashboard(id);
  }

  async create(data) {
    return this.mainframe.getWorld().fragments.createDashboard(data);
  }

  async update(id, data) {
    return this.mainframe.getWorld().fragments.updateDashboard(id, data);
  }

  async deleteOne(id) {
    return this.mainframe.getWorld().fragments.deleteDashboard(id);
  }

}

export default DashboardService;