import { h, resolveComponent } from 'vue';
import DOMPurify from "dompurify";
import marked from "marked";

function parseLink(a, config) {
  const href = a.getAttribute("href");

  if (!href) {
    return h('A', {}, a.innerText);
  }

  try {

    const url = new URL(href);
    // href is an absolute url. It's treated as an external link

    return h('A', { href: url.toString() }, a.innerText);

  } catch (err) {

    // href is an internal url. link to a fragment

    const handle = config.$handles.purify(href);

    const RouterLink = resolveComponent('RouterLink');

    return h(RouterLink,
      { 'to': { name: 'fragment-redirect', params: { handle: handle } } },
      {
        default() {
          return a.innerText;
        }
      }
    );


  }
}

function parseImage(i, config) {

  const src = i.getAttribute("src");

  if (!src) {
    return h('IMG', { src: "" }, i.innerText);
  }

  try {

    const url = new URL(src);
    // src is an absolute url. It's treated as an external link

    return h('IMG', { src: url.toString() }, i.innerText);

  } catch (err) {

    const handle = config.$handles.purify(src);
    const HostedImage = resolveComponent('hosted-image');

    return h(HostedImage, { handle: handle }, i.innerText);

  }

}

function parse(e, config) {

  if (e.tagName == 'A') {
    return parseLink(e, config);
  }

  if (e.tagName == 'IMG') {
    return parseImage(e, config);
  }

  const attributes = Object();

  for (const attr of e.attributes) {
    attributes[attr.name] = attr.nodeValue;
  }

  const children = [];
  for (const node of e.childNodes) {
    if (node.nodeType == Node.ELEMENT_NODE) {
      children.push(parse(node, config))
    }
    if (node.nodeType == Node.TEXT_NODE) {
      children.push(node.textContent)
    }
  }
  return h(e.tagName, attributes, children);
}

export default function (app) {
  app.component('markdown-render', {
    computed: {
      html() {
        if (typeof this.text == "string") {
          const markedHTML = marked(this.text);
          const cleanedHTML = DOMPurify.sanitize(markedHTML);

          return cleanedHTML;
        } else {
          return "<div>loading</div>";
        }
      }
    },
    render() {
      const config = {
        $service: this.$service,
        $handles: this.$handles
      }

      const el = document.createElement('div');
      el.innerHTML = this.html;

      const res = parse(el, config)
      return h('DIV', { class: 'content' }, [res]);
    },
    props: ["text"],
  });
}