<template>
  <div>
    <h1 class="title">This page doesn't exist</h1>
    <div class="field is-grouped">
      <router-link class="control button" :to="{ name: 'home' }">
        Home
      </router-link>
    </div>
  </div>
</template>

<script>
export default {};
</script>