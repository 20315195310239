<template><editor ref="editor" @change="$emit('change')" /></template>

<script>
import Editor from "@/markdown/Editor.vue";

export default {
  components: { Editor },
  props: ["initialArticle"],
  methods: {
    getText() {
      return this.$refs.editor.getText();
    },
  },
  watch: {
    initialArticle(newValue) {
      if (newValue) {
        this.$refs.editor.init(newValue.article.text);
      }
    },
  },
};
</script>
