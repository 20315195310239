class WorldService {

  constructor(mainframe) {
    this.mainframe = mainframe;
    this.connection = mainframe.connection;
  }

  getAll() {
    return this.connection.root.get("/worlds");
  }

  get(id) {
    return this.connection.root.get(`/worlds/${id}`);
  }

  getByHandle(handle) {
    return this.connection.root.get(`/worlds/handle/${handle}`);
  }

  getMembers(id) {
    return this.connection.root.get(`/worlds/members/${id}`);
  }

  updateMembers(id, data) {
    return this.connection.root.post(`/worlds/members/${id}`, data);
  }

  create(data) {
    return this.connection.root.post("/worlds", data);
  }

  async update(id, data) {
    // TODO : a less forceful solution, maybe ?
    const res = await this.connection.root.put(`/worlds/${id}`, data);
    this.mainframe.enterWorld(res.data.handle);
    await this.mainframe.fetchWorld();
  }

  deleteOne(id) {
    return this.connection.root.delete(`/worlds/${id}`);
  }

  findByName(name) {
    return this.connection.root.get(`/worlds?name=${name}`);
  }
}

export default WorldService;
