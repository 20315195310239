<template>
  <nav class="panel is-info">
    <div class="panel-heading level">
      <div class="level-left">
        <p class="level-item">{{ $world.name }}</p>
      </div>
      <div
        v-if="$store.world.canWrite && $route.name != 'article-create'"
        class="buttons has-addons is-justify-content-right is-flex"
      >
        <router-link
          class="button"
          :to="{ name: 'article-create', params: { type: 'none' } }"
        >
          <span class="icon">
            <i class="fas fa-plus"></i>
          </span>
        </router-link>
        <div class="dropdown" :class="{ 'is-active': dropdownActive }">
          <div class="dropdown-trigger">
            <button class="button" @click="openDropdown">
              <span class="icon is-small">
                <i class="fas fa-angle-down"></i>
              </span>
            </button>
          </div>
          <div class="dropdown-menu" role="menu">
            <div class="dropdown-content">
              <a
                v-for="type in types"
                :key="type"
                class="dropdown-item"
                @click="clickDropdownLink(type)"
              >
                {{ type.name }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="panel-block">
      <div class="field has-addons">
        <p class="control has-icons-left has-icons-right">
          <input
            class="input is-info"
            type="text"
            placeholder="Search for article"
            v-model="search"
            @input="searchChange"
          />
          <span class="icon is-left has-text-light">
            <i class="fas fa-search"></i>
          </span>
          <a
            class="icon is-right is-clickable has-text-light"
            @click="clearSearch"
          >
            <i class="fas fa-times"></i>
          </a>
        </p>
      </div>
    </div>
    <fragment-navigation ref="fragnav" />
  </nav>
</template>

<script>
import CreateDelayedTrigger from "@/utils/delayedTrigger.js";
import FragmentNavigation from "./FragmentNavigation.js";

const delayedTrigger = CreateDelayedTrigger(350);

export default {
  data() {
    return {
      warning: null,
      dropdownActive: false,
      search: "",
    };
  },
  computed: {
    types() {
      return this.$store.types;
    },
  },
  components: {
    FragmentNavigation,
  },
  methods: {
    refresh() {
      // this.$refs.fragnav.getData();
    },
    triggerSearch() {
      this.$refs.fragnav.filter(this.search);
    },
    searchChange() {
      delayedTrigger.trigger(this.triggerSearch);
    },
    clearSearch() {
      this.search = "";
      this.triggerSearch();
    },
    closeDropdown() {
      this.dropdownActive = false;
      window.onclick = null;
    },
    openDropdown() {
      this.dropdownActive = !this.dropdownActive;
      setTimeout(() => {
        // HACK
        window.onclick = this.closeDropdown;
      }, 100);
    },
    clickDropdownLink(type) {
      this.dropdownActive = false;
      this.$router.push({
        name: "article-create",
        params: {
          type: type.id,
        },
      });
    },
  },
  beforeUnmount() {
    window.onclick = null;
  },
};
</script>
