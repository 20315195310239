<template>
  <div>
    <h1 class="title">Welcome to the workshop.</h1>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>