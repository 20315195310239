<template>
  <div>
    <h1 class="title">
      Fragment with handle <em>{{ $route.params.article }}</em> not found.
    </h1>
  </div>
</template>

<script>
export default {};
</script>