class DebugService {

  constructor(mainframe) {
    this.mainframe = mainframe;
    this.connection = mainframe.connection;
    this.utils = [
      'misc',
      'workshop',
      'fastLogin',
      'clearDatabase',
      'seed',
    ]
  }

  async misc(app) {
    console.log('world : ', app.$world);
  }

  async workshop(app) {
    app.$router.push({
      name: "workshop"
    });
  }

  async fastLogin(app) {

    // try {
    //   await this.connection.root.post('/a/register', {
    //     username: 'alice',
    //     password: 'alice',
    //   });
    // } catch (err) {
    //   // skip
    // }

    try {
      const response = await this.connection.root.post('/a/login', {
        username: 'alice',
        password: 'alice12345678',
      });
      app.$session.setUser(response.data.user);
    } catch (err) {
      // skip
    }

  }

  async clearDatabase(app) {
    if (app.$session.user.id) {
      await this.connection.root.post('/a/logout', {});
      app.$session.clearUser();
    }

    return this.connection.root.post('/debug/cleardb', {});
  }

  async seed(app) {

    try {

      if (app.$session.user.id) {
        await this.connection.root.post('/a/logout', {});
        app.$session.clearUser();
      }

      await this.connection.root.post('/debug/cleardb', {});

      const users = [
        'alice',
        'bob',
        'carol',
        'dan',
        'eve',
      ];

      const userData = {};

      for (const u of users) {
        const response = await this.connection.root.post('/a/register', {
          username: u,
          password: u + '12345678',
        });
        userData[u] = response.data;
      }

      await this.connection.root.post('/debug/seed', userData);

      const res = await this.connection.root.post('/a/login', {
        username: 'alice',
        password: 'alice12345678',
      });

      app.$session.setUser(res.data.user);

      return {
        message: "seed successful"
      };

    } catch (err) {
      console.error(err);
    }

  }

}

export default DebugService;
