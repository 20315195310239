

function create(delay) {

  return {
    latestTrigger: Date.now(),
    trigger(callback) {
      const triggerTime = Date.now();
      this.latestTrigger = triggerTime;
      const cb = () => {
        if (this.latestTrigger == triggerTime) {
          callback();
        }
      }

      setTimeout(cb, delay);
    }
  }

}

export default create;