<template>
  <div>
    <router-view />
  </div>
</template>

<script>
export default {
  name: "Worldapp",
  data() {
    return {};
  },
  computed: {},
};
</script>
