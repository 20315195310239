<template>
  <div class="modal" :class="{ 'is-active': active }">
    <div class="modal-background" @click="cancel"></div>
    <div class="modal-card card">
      <span v-if="!choosingParent">
        <section class="modal-card-body">
          <div class="field">
            <label class="label">Name</label>
            <div class="control">
              <input class="input" v-model="typeData.name" />
            </div>
          </div>

          <hr class="is-primary" />

          <div class="columns">
            <div class="column">
              <div class="field">
                <label class="label">Properties</label>
              </div>

              <div
                class="field has-addons"
                v-for="(key, i) in typeData.keys"
                :key="i"
              >
                <div class="control">
                  <input class="input" v-model="key.text" />
                </div>
                <div class="control">
                  <button class="button" @click="removeKey(i)">
                    <i class="fas fa-trash-alt"></i>
                  </button>
                </div>
              </div>

              <button class="field button" @click="addKey">
                <i class="fas fa-plus"></i>
              </button>
            </div>
            <div class="column">
              <div class="field">
                <label class="label">Parent Types</label>
              </div>

              <div
                class="field has-addons"
                v-for="type in typeData.parents"
                :key="type"
              >
                <div class="control">
                  <input class="input" v-model="type.name" readonly />
                </div>
                <div class="control">
                  <button class="button" @click="removeParent(type)">
                    <i class="fas fa-trash-alt"></i>
                  </button>
                </div>
              </div>

              <button class="field button" @click="addParent">
                <i class="fas fa-plus"></i>
              </button>
            </div>
          </div>
        </section>
        <footer class="card-footer">
          <a class="card-footer-item is-warning" @click="save"> Confirm </a>
          <a class="card-footer-item" @click="cancel">Cancel</a>
        </footer>
      </span>
      <span v-if="choosingParent">
        <section class="modal-card-body">
          <div class="field" v-for="type in availableParents" :key="type">
            <div class="control">
              <a
                class="button is-fullwidth is-dark"
                @click="confirmParent(type)"
              >
                <div style="margin-right: auto">
                  {{ type.name }}
                </div>
              </a>
            </div>
          </div>
        </section>
        <footer class="card-footer">
          <a class="card-footer-item" @click="cancelAddParent">Back</a>
        </footer>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: false,
      choosingParent: false,
      typeData: {
        name: "",
        keys: [],
        parents: [],
      },
      typeInit: null,
      errorMessage: null,
    };
  },
  props: ["typeList"],
  computed: {
    availableParents() {
      return this.typeList.filter((type) => {
        return (
          type.id != this.typeInit?.id &&
          !this.typeData.parents.find((t) => t.id == type.id)
        );
      });
    },
  },
  methods: {
    open(typeInit) {
      this.typeInit = typeInit;
      if (!typeInit) {
        typeInit = {
          name: "",
          keys: [],
          parents: [],
        };
      }
      this.choosingParent = false;
      this.typeData.name = typeInit.name;
      this.typeData.keys = [];
      for (const key of typeInit.keys) {
        this.typeData.keys.push({
          text: key,
        });
      }
      this.typeData.parents = [];
      for (const p of typeInit.parents) {
        const t = this.typeList.find((t) => {
          return t.id == p.parentId;
        });
        this.typeData.parents.push(t);
      }
      this.active = true;
    },
    async create() {
      const data = {
        name: this.typeData.name,
        keys: [],
      };
      for (const key of this.typeData.keys) {
        data.keys.push(key.text);
      }

      const response = await this.$service.type.create(data);

      const id = response.id;

      const add = [];

      for (const t of this.typeData.parents) {
        add.push({
          child: id,
          parent: t.id,
        });
      }

      await this.$service.type.updateParenthood({
        add: add,
        remove: [],
      });
    },
    async update() {
      const data = {
        name: this.typeData.name,
        keys: [],
      };
      for (const key of this.typeData.keys) {
        data.keys.push(key.text);
      }

      await this.$service.type.update(this.typeInit.id, data);

      // update parenthood

      const add = [];
      const remove = [];

      for (const t of this.typeData.parents) {
        if (
          !this.typeInit.parents.find((p) => {
            return p.parentId == t.id;
          })
        ) {
          add.push({
            child: this.typeInit.id,
            parent: t.id,
          });
        }
      }

      for (const p of this.typeInit.parents) {
        if (
          !this.typeData.parents.find((t) => {
            return p.parentId == t.id;
          })
        ) {
          remove.push({
            child: this.typeInit.id,
            parent: p.parentId,
          });
        }
      }

      await this.$service.type.updateParenthood({
        add: add,
        remove: remove,
      });
    },
    async save() {
      try {
        if (!this.typeInit) {
          await this.create();
        } else {
          await this.update();
        }
        this.$emit("refresh");
        this.active = false;
      } catch (err) {
        this.errorMessage = err.message;
      }
    },
    cancel() {
      this.active = false;
    },
    addKey() {
      this.typeData.keys.push({ text: "" });
    },
    removeKey(index) {
      this.typeData.keys.splice(index, 1);
    },
    addParent() {
      this.choosingParent = true;
    },
    confirmParent(type) {
      this.typeData.parents.push(type);
      this.choosingParent = false;
    },
    cancelAddParent() {
      this.choosingParent = false;
    },
    removeParent(type) {
      const i = this.typeData.parents.indexOf(type);
      this.typeData.parents.splice(i, 1);
    },
  },
};
</script>