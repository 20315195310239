<template>
  <side-template>
    <template #side>
      <settings-side-panel />
    </template>
    <template #center>
      <router-view />
    </template>
  </side-template>
</template>

<script>
import SideTemplate from "@/components/templates/SideTemplate.vue";
import SettingsSidePanel from "./settings/SettingsSidePanel.vue";

export default {
  components: {
    SideTemplate,
    SettingsSidePanel,
  },
};
</script>
