<template>
  <div>
    <div class="level is-flex is-flex-direction-row">
      <div class="level-left">
        <a v-if="!edit" class="level-item mb-0 mr-1" @click="edit = true">
          <span class="icon is-small">
            <i class="fas fa-cog"></i>
          </span>
        </a>
        <a v-if="edit" class="level-item mb-0 mr-1" @click="confirm">
          <span class="icon is-small">
            <i class="fas fa-check"></i>
          </span>
        </a>
        <span class="level-item">Handles :</span>
        <div class="level-item" v-if="!edit">
          <div class="level is-flex is-flex-direction-row level-left">
            <div class="ml-3" v-for="handle in handles" :key="handle">
              <em> {{ handle }} </em>
            </div>
          </div>
        </div>
        <div class="level-item" v-if="edit">
          <div class="level is-flex is-flex-direction-row level-left">
            <div class="level-item" v-for="(handle, i) in handles" :key="i">
              <input
                :style="getStyle(handle)"
                v-model="handles[i]"
                @input="$emit('change')"
              />
              <a class="button" @click="deleteItem(i)">
                <span class="icon is-small">
                  <i class="fas fa-times"></i>
                </span>
              </a>
            </div>
            <a class="level-item button" @click="createItem">
              <span class="icon is-small">
                <i class="fas fa-plus"></i>
              </span>
            </a>
          </div>
        </div>
        <help-box class="level-item">
          Handles are used to create links between articles. You can add any
          number of handles to this article. In any other article, you can
          create a link to this article with the syntax [link text](handle)
        </help-box>
      </div>
    </div>
    <div class="has-text-danger" v-if="hasError">
      {{ $handles.errorMessage }}
    </div>
  </div>
</template>

<script>
import HelpBox from "../../../templates/HelpBox.vue";
function removeDoubles(arr) {
  var i = 0;
  while (i < arr.length) {
    var j = i + 1;
    while (j < arr.length) {
      if (arr[j] == arr[i]) {
        arr.splice(j, 1);
      } else {
        j++;
      }
    }
    i++;
  }
}

export default {
  props: ["initialArticle"],
  name: "handles-edit",
  components: { HelpBox },
  data() {
    return {
      handles: [],
      errors: {},
      hasError: false,
      edit: false,
    };
  },
  methods: {
    getHandles() {
      removeDoubles(this.handles);
      return this.handles;
    },
    deleteItem(i) {
      this.handles.splice(i, 1);
      this.$emit("change");
    },
    createItem() {
      this.handles.push("");
      this.$emit("change");
    },
    confirm() {
      var err = false;
      for (const handle of this.handles) {
        if (!this.$handles.verify(handle)) {
          this.errors[handle] = true;
          err = true;
        } else {
          this.errors[handle] = false;
        }
      }
      if (err) {
        this.hasError = true;
      } else {
        removeDoubles(this.handles);
        this.hasError = false;
        this.edit = false;
      }
    },
    getStyle(handle) {
      if (this.errors[handle]) {
        return {
          color: "red",
          "border-color": "red",
        };
      } else {
        return {};
      }
    },
  },
  watch: {
    initialArticle(newValue) {
      if (newValue) {
        this.handles = newValue.handles.filter((h) => this.$handles.verify(h));
      }
    },
  },
};
</script>