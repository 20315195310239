<template>
  <div>
    <div class="field is-grouped">
      <div class="control is-expanded">
        <input class="input is-medium has-text-weight-bold" v-model="name" />
      </div>
    </div>
    <h2 class="subtitle">
      Welcome to the placeholder dashboard editor. Dashboards haven't been
      implemented yet.
    </h2>
    <div class="mt-2 ml-2 mr-2">
      <textarea v-model="tempContent" />
    </div>
    <br />
    <div v-if="errorMessage" class="mb-3 has-text-danger">
      {{ errorMessage }}
    </div>
    <a class="button mr-3" v-on:click="save">Save</a>
    <a class="button" v-on:click="discard">Discard</a>
  </div>
</template>

<script>
export default {
  data() {
    return {
      initialDashboard: {},
      create: true,
      tempContent: "",
      name: "",
      errorMessage: null,
    };
  },
  methods: {
    async getEditData() {
      this.handle = this.$route.params.handle;
      try {
        const res = await this.$service.dashboard.get(this.handle);
        this.initialDashboard = res.data;
      } catch (err) {
        // skip
      }
    },
    async getCreateData() {
      const emptyDashboard = {
        dashboard: {
          text: "",
        },
        handles: [],
        name: "New Dashboard",
        props: Object({}),
        types: [],
      };
      this.initialDashboard = emptyDashboard;
    },
    async save() {
      const data = {
        name: this.name,
        dashboard: {
          tempContent: this.tempContent,
        },
        props: this.initialDashboard.props,
        types: this.initialDashboard.types,
        handles: this.initialDashboard.handles,
      };

      if (this.create) {
        try {
          const response = await this.$service.dashboard.create(data);
          this.$emit("refresh");
          this.$router.push({
            name: "dashboard",
            params: {
              handle: response.data.id,
            },
          });
        } catch (e) {
          this.errorMessage = e.message;
        }
      } else {
        try {
          await this.$service.dashboard.update(this.initialDashboard.id, data);
          this.$emit("refresh");
          this.$router.push({ name: "dashboard" });
        } catch (e) {
          this.errorMessage = e.message;
        }
      }
    },
    async discard() {
      this.$safeguard.clear();
      this.confModalActive = false;
      if (this.$route.params.handle) {
        this.$router.push({ name: "dashboard" });
      } else {
        this.$router.push({ name: "fragment-explorer" });
      }
    },
  },
  async mounted() {
    if (this.$route.name == "dashboard-create") {
      this.create = true;
    } else {
      if (this.$route.name == "dashboard-edit") {
        this.create = false;
      } else {
        console.error(`Unrecognized route name : ${this.$route.name}`);
        return;
      }
    }
    if (this.create) {
      await this.getCreateData();
    } else {
      await this.getEditData();
    }

    this.name = this.initialDashboard.name;
    this.tempContent = this.initialDashboard.dashboard.tempContent;
  },
};
</script>,