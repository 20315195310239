<template>
  <p class="buttons has-addons all">
    <span class="buttons left">
      <button class="button is-small top-left" @click="emitUp">
        <span class="icon is-small">
          <i class="fas fa-arrow-up"></i>
        </span>
      </button>
      <button class="button is-small bottom-left" @click="emitDown">
        <span class="icon is-small">
          <i class="fas fa-arrow-down"></i>
        </span>
      </button>
    </span>
    <button class="button right" @click="$emit('click', 'remove')">
      <span class="icon is-small">
        <i class="fas fa-trash"></i>
      </span>
    </button>
  </p>
</template>

<script>
export default {
  data() {
    return {
      n: 0,
    };
  },
  watch: {
    up() {
      this.n++;
    },
    down() {
      this.n++;
    },
  },
  props: {
    up: Boolean,
    down: Boolean,
  },
  methods: {
    emitUp() {
      if (this.up) {
        this.$emit("click", "up");
      }
    },
    emitDown() {
      if (this.down) {
        this.$emit("click", "down");
      }
    },
  },
};
</script>

<style scoped>
.all {
  flex-direction: line;
  flex-wrap: inherit;
}

.left {
  flex-direction: column;
  margin-right: -1px !important;
  margin-bottom: 0.5em !important;
}
.top-left {
  height: 21px !important;
  width: 40px !important;
  margin: 0 !important;
  margin-bottom: -1px !important;
  border-top-left-radius: 4px !important;
}
.bottom-left {
  margin-top: -1px !important;
  height: 20px !important;
  width: 40px !important;
  margin: 0 !important;
  border-bottom-left-radius: 4px !important;
}
.right {
  margin-bottom: 0.5em !important;
}
</style>