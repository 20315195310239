<template>
  <div class="field">
    <div class="field-body pl-3 is-flex is-flex-direction-row">
      <div class="field is-flex-grow-1 mb-0 mr-3">
        <p class="control is-expanded">
          <input
            class="input"
            type="text"
            placeholder="Key"
            :value="name"
            @input="$emit('update:name', $event.target.value)"
          />
        </p>
      </div>
      <div class="field is-flex-grow-1 mb-0 mr-3">
        <p class="control is-expanded">
          <input
            class="input"
            type="text"
            placeholder="Value"
            :value="value"
            @input="$emit('update:value', $event.target.value)"
          />
        </p>
      </div>

      <!-- TODO: link up and down once bug fixed in vue-js -->
      <icons @click="click" :up="up" :down="down" />
    </div>
  </div>
</template>

<script>
import Icons from "./Icons.vue";

export default {
  props: {
    name: String,
    value: String,
    up: Boolean,
    down: Boolean,
  },
  components: {
    Icons,
  },
  methods: {
    click(event) {
      this.$emit("click", event);
    },
  },
};
</script>
