import { createWebHistory, createRouter } from "vue-router";

import routes from './routes';

export default {

  // We wrap the router in a custom plugin because we need access to app.$service
  install(app) {
    const router = createRouter({
      history: createWebHistory(),
      routes,
    });

    router.beforeEach(async (to, from, next) => {

      const mainframe = app.config.globalProperties.$mainframe;
      const safeguard = app.config.globalProperties.$safeguard;

      if (!safeguard.request(to)) {
        next(false);
        return;
      }

      const worldHandle = to.params.worldHandle;
      if (worldHandle) {
        try {
          await mainframe.enterWorld(worldHandle);
          next(true);
        } catch (err) {
          console.error(err);
          next({ name: 'home' });
        }
      } else {
        next(true);
      }

    })

    router.beforeEach((to, from, next) => {

      const service = app.config.globalProperties.$service;
      if (to.name == 'fragment-redirect') {
        const fragment = service.fragment.get(to.params.handle);
        if (fragment.content == 'Article') {
          next({
            name: 'article',
            params: to.params
          });
          return;
        }

        next({
          name: 'fragment-not-found',
          params: to.params
        });
      } else {
        next(true);
      }
    });

    router.afterEach((to) => {
      const mainframe = app.config.globalProperties.$mainframe;
      const worldHandle = to.params.worldHandle;
      if (!worldHandle) {
        mainframe.exitWorld();
      }
    });

    app.use(router);

  }
}
