<template>
  <div class="modal" :class="{ 'is-active': active }">
    <div class="modal-background" @click="cancel"></div>
    <div class="modal-card card">
      <section class="modal-card-body">
        <slot></slot>
      </section>
      <footer class="card-footer">
        <a class="card-footer-item is-warning" @click="confirm"> Confirm </a>
        <a class="card-footer-item" @click="cancel">Cancel</a>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: "confirmation-modal",
  data() {
    return {
      active: false,
    };
  },
  methods: {
    open() {
      this.active = true;
    },
    confirm() {
      this.$emit("confirm");
    },
    cancel() {
      this.active = false;
    },
  },
};
</script>
