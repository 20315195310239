<template>
  <div class="container is-max-widescreen">
    <h1 class="title">Login</h1>

    <div class="field">
      <label class="label">Username</label>
      <div class="control has-icons-left">
        <input
          id="username-input"
          class="input"
          type="text"
          name="username"
          v-model="username"
          placeholder="username"
          @keypress="forward"
        />
        <span class="icon is-small is-left">
          <i class="fas fa-user"></i>
        </span>
      </div>
    </div>

    <div class="field">
      <label class="label">Password</label>
      <div class="control has-icons-left">
        <input
          id="password-input"
          class="input"
          type="password"
          name="password"
          v-model="password"
          placeholder="password"
          @keypress="forward"
        />
        <span class="icon is-small is-left">
          <i class="fas fa-lock"></i>
        </span>
      </div>
    </div>

    <div class="has-text-danger" v-if="errorMessage">
      {{ errorMessage }}
    </div>

    <div class="field is-grouped">
      <div class="control">
        <button class="button is-link" @click="submit">Login</button>
      </div>
      <div class="control mt-2">
        <router-link class="" to="/register">Register</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      username: "",
      password: "",
      errorMessage: null,
    };
  },
  methods: {
    forward(event) {
      if (event.key == "Enter") {
        if (event.originalTarget.id == "username-input") {
          document.getElementById("password-input").focus();
        }
        if (event.originalTarget.id == "password-input") {
          this.submit();
        }
      }
    },
    async submit() {
      try {
        if (this.username == "") {
          this.errorMessage = `You must provide a username`;
          return;
        }
        if (this.password == "") {
          this.errorMessage = `You must provide a password`;
          return;
        }
        await this.$service.auth.login({
          username: this.username,
          password: this.password,
        });
        this.$router.push("/");
      } catch (err) {
        this.errorMessage = err.message;
      }
    },
  },
};
</script>
