import axios from "axios";

const baseURL = 'https://api.imgur.com/3';

class ImgurService {

  constructor(config) {
    this.clientId = config.clientId;
    this.http = axios.create({
      baseURL: baseURL,
      headers: {
        "Authorization": `Client-ID ${config.clientId}`,
      }
    })
  }

  async uploadImage(data) {

    const formData = new FormData();

    formData.append('image', data.image);
    formData.append('type', 'file');
    formData.append('title', 'Artefact_' + Date.now());

    try {
      const res = await this.http.post('/upload/', formData);
      return res.data;
    } catch (err) {
      console.error(err);
    }
  }

  async getImage(id) {
    try {
      const res = await this.http.get(`/image/${id}`);
      return res.data;
    } catch (err) {
      console.error(err);
    }
  }

  async deleteImage(deletehash) {
    try {
      const res = await this.http.delete(`/image/${deletehash}`);
      return res.data;
    } catch (err) {
      console.error(err);
    }
  }

  async getCredit() {
    try {
      const res = await this.http.get(
        '/credits'
      );
      return res;
    } catch (err) {
      console.error(err);
    }
  }
}

export default ImgurService;