<template>
  <div>
    <div v-if="!editing" class="level">
      <div class="level-left is-flex is-flex-direction-row">
        <a class="level-item mb-0 mr-1" @click="edit">
          <span class="icon is-small">
            <i class="fas fa-cog"></i>
          </span>
        </a>
        <h1 class="level-item mb-0 mr-1">Types :</h1>
        <div class="level-item" v-for="type in myTypes" :key="type">
          {{ type.name }}
        </div>
      </div>
    </div>
    <section class="section" v-if="editing">
      <div v-for="tc in typesCheckModel" :key="tc" class="level">
        <div class="level-left">
          <input
            class="checkbox level-item"
            type="checkbox"
            @input="detectInput = true"
            v-model="tc.chosen"
          />
          <div class="level-item">{{ tc.name }}</div>
          <div class="level-item" v-if="tc.implied">
            <span class="icon is-small">
              <i class="fas fa-check"></i>
            </span>
          </div>
        </div>
      </div>
      <div class="level">
        <div class="level-left">
          <button class="button level-item" @click="confirm">confirm</button>
          <button class="button level-item" @click="cancel">cancel</button>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  props: ["initialArticle", "types"],
  data() {
    return {
      editing: false,
      myTypes: [],
      typesCheckModel: [],
      inputDetected: false,
    };
  },
  methods: {
    getMyTypes() {
      return this.myTypes;
    },
    computeImplication() {
      const TCM = this.typesCheckModel;
      for (const type of TCM) {
        type.implied = false;
      }
      function addParents(t) {
        for (const pid of t.parents) {
          const p = TCM.find((p) => p.id == pid);
          if (p) {
            p.implied = true;
            addParents(p);
          }
        }
      }
      for (const type of TCM) {
        if (type.chosen) {
          addParents(type, 0);
        }
      }
    },
    edit() {
      this.typesCheckModel = [];
      for (const type of this.types) {
        this.typesCheckModel.push({
          name: type.name,
          id: type.id,
          chosen: Boolean(this.myTypes.find((t) => t.id == type.id)),
          implied: false,
          parents: type.parents.map((parenthood) => parenthood.parentId),
        });
      }
      this.computeImplication();
      this.editing = true;
    },
    detectInput() {
      this.inputDetected = true;
      this.$emit("change");
    },
    confirm() {
      this.myTypes = [];
      for (const tc of this.typesCheckModel) {
        if (tc.chosen) {
          const type = this.types.find((type) => type.id == tc.id);
          if (type) {
            this.myTypes.push(type);
          } else {
            console.error("type not found");
            this.myTypes.push({
              id: tc.id,
              name: tc.name,
              parents: [],
              keys: [],
            });
          }
        }
      }
      this.$emit("typesUpdate", this.myTypes);
      this.editing = false;
    },
    cancel() {
      this.editing = false;
    },
  },
  watch: {
    initialArticle(newValue) {
      if (newValue) {
        this.myTypes = [];
        for (const btt of newValue.types) {
          const type = this.types.find((type) => type.id == btt.typeId);
          if (type) {
            this.myTypes.push(type);
          } else {
            console.error("type not found");
            this.myTypes.push({
              id: btt.typeId,
              name: "[type data not found]",
              parents: [],
              keys: [],
            });
          }
        }
      }
    },
    typesCheckModel: {
      handler() {
        if (this.inputDetected) {
          this.inputDetected = false;
          this.computeImplication();
        }
      },
      deep: true,
    },
  },
};
</script>