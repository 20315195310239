<template>
  <div class="container is-fluid">
    <h1 class="title">General Settings</h1>
    <div>
      <div class="field">
        <label class="label">Name</label>
        <div class="control has-icons-left">
          <input
            class="input"
            type="text"
            name="name"
            v-model="world_data.name"
            placeholder="name"
          />
          <span class="icon is-small is-left">
            <i class="fas fa-globe"></i>
          </span>
        </div>
      </div>

      <div class="field">
        <div class="label">
          <span class="icon-text">
            <span>URL</span>
            <help-box>
              This will be the URL of your world. If you do not want to share
              the world, you can leave a random value so that only people with
              the link can access it. However, if you intend to share this world
              publicly, it is better to use a readable url. The name of your
              world is a good choice.
            </help-box>
          </span>
        </div>
        <div class="field-body">
          <div class="field is-expanded">
            <div class="field has-addons">
              <p class="control">
                <a class="button is-static"> {{ origin }}/w/ </a>
              </p>
              <p class="control is-expanded">
                <input
                  :class="{
                    input: true,
                    'level-item': true,
                    'is-danger': !isValidHandle,
                  }"
                  type="text"
                  name="handle"
                  v-model="world_data.handle"
                  placeholder="worldurl"
                />
              </p>
            </div>

            <p class="help is-danger" v-if="!isValidHandle">
              URL can only contain letters, numbers and underscores
            </p>
          </div>
        </div>
      </div>

      <div class="field">
        <label class="label">Description</label>
        <div class="control">
          <textarea
            class="textarea"
            v-model="world_data.description"
            placeholder="Awesome world with magical turnips"
            rows="3"
            cols="100"
          />
        </div>
      </div>

      <div class="field">
        <label class="label">Visibility</label>
        <div class="control">
          <label class="checkbox">
            <input
              class="checkbox"
              type="checkbox"
              name="isPrivate"
              v-model="world_data.isPrivate"
            />
            <span class="icon-text ml-2">
              <span>Private</span>
              <span class="icon">
                <i class="fas fa-lock" />
              </span>
            </span>
          </label>
        </div>
      </div>

      <div class="field is-grouped">
        <div class="control">
          <button class="button is-link" @click="submit">Submit</button>
        </div>
        <div class="control">
          <button
            class="button is-link is-light"
            @click="$router.push({ name: 'world-home' })"
          >
            Cancel
          </button>
        </div>
      </div>

      <div
        class="has-text-danger"
        v-if="updateError"
        v-text="updateError"
      ></div>
    </div>
  </div>
</template>

<script>
import HelpBox from "../../templates/HelpBox.vue";

export default {
  name: "settings-general",
  components: { HelpBox },
  data() {
    return {
      world_data: {
        name: "",
        handle: "",
        description: "",
        isPrivate: false,
      },
      updateError: "",
    };
  },
  computed: {
    isValidHandle() {
      return this.$handles.verify(this.world_data.handle);
    },
    origin() {
      const url = new URL(window.location.href);
      return url.origin;
    },
  },
  methods: {
    async submit() {
      if (!this.world_data.name) {
        this.updateError = "You must choose a name";
        return;
      }

      if (!this.world_data.handle) {
        this.updateError = "You must choose a url";
        return;
      }

      if (!this.isValidHandle) {
        this.updateError = "Please choose a valid url";
        return;
      }

      try {
        const data = {
          name: this.world_data.name,
          handle: this.world_data.handle,
          description: this.world_data.description,
          isPrivate: this.world_data.isPrivate,
        };

        await this.$service.world.update(this.$world.id, data);

        this.$router.push({
          name: "world-home",
          params: { worldHandle: this.$world.handle },
        });
      } catch (err) {
        if (err.message) {
          this.updateError = err.message;
        } else {
          this.updateError = "An unknow error occured";
        }
      }
    },
  },
  mounted() {
    const currentWorld = {};
    for (const key of ["name", "handle", "description", "isPrivate"]) {
      currentWorld[key] = this.$world[key];
    }
    this.world_data = currentWorld;
  },
};
</script>
