
const placeholder = {
  id: -1,
  name: "",
  props: {},
  handles: [],
  content: 'Article',
  types: [],
  article: {
    text: ""
  }
}

class ArticleService {

  constructor(mainframe) {
    this.mainframe = mainframe;
  }

  get(handle) {
    if (this.mainframe.app.config.globalProperties.$store.fragments.length == 0) {
      return placeholder;
    }
    return this.mainframe.getWorld().fragments.getArticle({
      handle: handle
    });
  }

  async fetch(id) {
    return this.mainframe.getWorld().fragments.fetchArticle(id);
  }

  async create(data) {
    return this.mainframe.getWorld().fragments.createArticle(data);
  }

  async update(id, data) {
    return this.mainframe.getWorld().fragments.updateArticle(id, data);
  }

  async deleteOne(id) {
    return this.mainframe.getWorld().fragments.deleteArticle(id);
  }
}

export default ArticleService;
