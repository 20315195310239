<template>
  <section class="hero">
    <div class="hero-body">
      <div class="container has-text-centered">
        <h1 class="title">
          {{ $world.name }}
        </h1>
        <markdown-render :text="$world.description" />
      </div>
    </div>

    <div class="hero-foot">
      <div class="container has-text-centered">
        <div class="columns is-mobile">
          <div class="column">
            <router-link class="box center" :to="{ name: 'fragment-explorer' }">
              <h2 class="subtitle">Articles</h2>
              <span class="icon is-large">
                <i class="fas fa-2x fa-cube"></i>
              </span>
            </router-link>
          </div>
          <div class="column container" v-if="$world.isOwner">
            <router-link class="box center" :to="{ name: 'settings-general' }">
              <h2 class="subtitle">Settings</h2>
              <span class="icon is-large">
                <i class="fas fa-2x fa-cog"></i>
              </span>
            </router-link>
          </div>
          <div class="column container">
            <router-link class="box center" :to="{ name: 'image-explorer' }">
              <h2 class="subtitle">Images</h2>
              <span class="icon is-large">
                <i class="fas fa-2x fa-images"></i>
              </span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "WorldHome",
  data() {
    return {};
  },
};
</script>

<style scoped>
.center {
  margin-left: auto;
  margin-right: auto;
  max-width: 137px;
  text-align: center;
}
</style>
