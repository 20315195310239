<template>
  <div class="container">
    <div class="columns is-centered is-multiline">
      <div class="column is-2-widescreen is-one-quarter-desktop is-6-tablet">
        <div
          class="card mt-2 is-clickable"
          v-if="$world.canWrite"
          @click="$refs.uploadModal.open"
        >
          <div class="card-image">
            <figure class="image is-4by3">
              <img src="/images/plus-solid.svg" alt="+" />
            </figure>
          </div>
          <div class="card-content">
            <div class="media">
              <div class="media-content">
                <p class="title is-4">Upload</p>
                <p class="subtitle is-6">&nbsp;</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="column is-2-widescreen is-one-quarter-desktop is-6-tablet"
        v-for="image in imageList"
        :key="image"
      >
        <image-list-item
          :imagedata="image"
          @clicked="$refs.imageModal.open(image)"
        />
      </div>
    </div>
    <image-modal ref="imageModal" />
    <image-upload-modal @uploaded="imageUploaded" ref="uploadModal" />
  </div>
</template>

<script>
import ImageListItem from "./ImageListItem.vue";
import ImageModal from "./imageModal.vue";
import ImageUploadModal from "./ImageUploadModal.vue";

export default {
  data() {
    return {
      imageFocus: {},
      modalActive: false,
      uploadModalActive: false,
      uploadErrorMessage: null,
      updateErrorMessage: null,
    };
  },
  computed: {
    imageList() {
      return this.$store.resources;
    },
  },
  methods: {
    async imageUploaded(data) {
      const img = this.imageList.find((img) => {
        return img.handle == data.handle;
      });
      if (img) {
        this.$refs.imageModal.open(img);
      }
    },
  },

  components: {
    ImageListItem,
    ImageModal,
    ImageUploadModal,
  },
};
</script>