<template>
  <div>
    <div>
      <div class="field is-grouped" v-for="type in typesDisplay" :key="type">
        <div class="control is-expanded" style="max-width: 75%">
          <a
            class="button is-medium is-fullwidth is-dark"
            @click="clickType(type)"
            :class="{ 'ml-4': type.parents.length > 0 }"
          >
            <div style="margin-right: auto">
              {{ type.name }}
            </div>
          </a>
        </div>
      </div>
    </div>
    <button class="button mt-5" @click="clickCreateType">CreateNewType</button>
    <type-edit-modal ref="tem" :typeList="$store.types" />
    <type-view-modal
      ref="tvm"
      :typeList="$store.types"
      @edit="editType"
      @delete="deleteType"
    />
  </div>
</template>

<script>
import TypeEditModal from "./TypeEditModal.vue";
import TypeViewModal from "./TypeViewModal.vue";

export default {
  data() {
    return {
      typeFocus: null,
    };
  },

  computed: {
    typesDisplay() {
      try {
        const tl = [];
        const types = this.$store.types;

        for (const type of types) {
          type.included = false;
        }

        const add = function (type) {
          tl.push(type);
          type.included = true;
          for (const prt of type.children) {
            const child = types.find((type) => type.id == prt.childId);
            if (!child.included) {
              add(child);
            }
          }
        };

        for (const type of types) {
          if (type.parents.length == 0) {
            add(type);
          }
        }

        return tl;
      } catch (err) {
        console.error(err);
        return this.types;
      }
    },
  },

  methods: {
    clickType(t) {
      this.typeFocus = t;
      this.$refs.tvm.open(t);
    },
    async deleteType() {
      try {
        await this.$service.type.deleteOne(this.typeFocus.id);
      } catch (err) {
        // skip
      }
    },
    editType() {
      this.$refs.tem.open(this.typeFocus);
    },
    clickCreateType() {
      this.$refs.tem.open();
    },
  },
  components: {
    TypeEditModal,
    TypeViewModal,
  },
};
</script>