<template><div></div></template>

<script>
export default {
  mounted() {
    const fragment = this.$service.fragment.get(this.$router.params.handle);

    if (fragment.content == "Article") {
      this.$router.push({
        name: "article",
      });
      return;
    }

    this.$router.push({
      name: "fragment-not-found",
    });
  },
};
</script>