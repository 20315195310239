import WorldHome from "@/components/worldapp/WorldHome";
import WorldMembers from "@/components/worldapp/settings/WorldMembers";

// Fragments

import FragmentExplorer from "@/components/worldapp/fragment/FragmentExplorer"
import FragmentRedirect from "@/components/worldapp/fragment/FragmentRedirect";
import FragmentNotFound from "@/components/worldapp/fragment/FragmentNotFound";
// import ExplorerHome from "@/components/worldapp/fragment/ExplorerHome";

// Articles

import Article from "@/components/worldapp/article/Article";
import ArticleEditor from "@/components/worldapp/article/ArticleEditor";

// Dashboards

import Dashboard from "@/components/worldapp/dashboard/Dashboard";
import DashboardEditor from "@/components/worldapp/dashboard/DashboardEditor";

// Images

import ImageExplorer from "@/components/worldapp/images/ImageExplorer";

// Settings

import Settings from "@/components/worldapp/Settings";
import GeneralSettings from "@/components/worldapp/settings/GeneralSettings";
import Layout from "@/components/worldapp/settings/Layout";
import DangerZone from "@/components/worldapp/settings/DangerZone";

export default [
  {
    path: 'home',
    name: "world-home",
    component: WorldHome
  },
  {
    path: '',
    name: 'fragment-explorer',
    component: FragmentExplorer,
    children: [
      {
        path: 'f/:handle',
        name: "fragment-redirect",
        component: FragmentRedirect,
      },
      {
        path: 'not-found/:handle',
        name: "fragment-not-found",
        component: FragmentNotFound,
        props: true,
      },
      {
        path: 'a/view/:handle',
        name: "article",
        component: Article,
        props: true,
      },
      {
        path: 'a/edit/:handle',
        name: "article-edit",
        component: ArticleEditor,
        props: true,
      },
      {
        path: 'a/new/:type',
        name: "article-create",
        component: ArticleEditor,
        props: true,
      },
      {
        path: 'd/view/:handle',
        name: "dashboard",
        component: Dashboard,
        props: true,
      },
      {
        path: 'd/edit/:handle',
        name: "dashboard-edit",
        component: DashboardEditor,
        props: true,
      },
      {
        path: 'd/new/:type',
        name: "dashboard-create",
        component: DashboardEditor,
        props: true,
      },

    ]
  },
  {
    path: 'i',
    name: 'image-explorer',
    component: ImageExplorer
  },
  {
    path: 'settings',
    name: 'world-settings',
    component: Settings,
    children: [
      {
        path: 'general',
        name: 'settings-general',
        component: GeneralSettings
      },
      {
        path: 'members',
        name: 'settings-members',
        component: WorldMembers
      },
      {
        path: 'layout',
        name: 'settings-layout',
        component: Layout,
      },
      {
        path: 'danger',
        name: 'settings-danger',
        component: DangerZone,
      },
    ]
  },
];
