import axios from "axios";
import SettingsFile from "@/settings.js";

class Connection {
  constructor(app) {

    this.app = app;
    this.baseURL = SettingsFile.baseURL;

    this.root = axios.create({
      baseURL: SettingsFile.baseURL,
      headers: {
        "Content-type": "application/json"
      },
      withCredentials: true,
    });

    this.root.interceptors.response.use((res) => (res), (err) => this.errorInterceptor(err));

    this.world = null;
    this.worldHandle = null;
  }

  enterWorld(worldHandle) {
    this.world = axios.create({
      baseURL: `${this.baseURL}/w/${worldHandle}`,
      headers: {
        "Content-type": "application/json"
      },
      withCredentials: true,
    })
  }

  errorInterceptor(err) {


    const data = err.response?.data;
    const status = err.response?.status;

    if (status == 403) {
      // In case of 403 error, the client logs out
      const session = this.app.config.globalProperties.$session;
      session.clearUser();
    }

    var type;
    if (status == 400) {
      type = "warning";
    } else {
      type = "error";
      console.error(err);
    }

    var message = "An unknown error occured";
    if (data?.message) {
      message = data.message;
    }
    if (typeof data == "string") {
      message = data;
    }
    if (status == 404) {
      message = "404 : " + message;
    }

    if (status == 413) {
      message = `413 : Payload too large. If you are writing an article, try to split it into several articles.`;
    }

    if (message.length > 300) {
      message = message.substring(0, 300) + " ...";
    }

    this.app.config.globalProperties.$log.push({
      type: type,
      message: message
    });


    return Promise.reject(data);
  }
}

export default Connection;