<template>
  <div>
    <div :style="overlay"></div>
    <h1 class="title">Danger Zone!</h1>
    <button class="button is-danger" @click="modalActive = true">
      Delete World
    </button>
    <div class="modal" :class="{ 'is-active': modalActive }">
      <div class="modal-background" @click="cancel"></div>
      <div class="modal-card card">
        <header class="modal-card-head">
          <p class="modal-card-title">
            Are you sure you want to delete the world {{ $world.name }} ?
          </p>
        </header>
        <section class="modal-card-body">
          <p>
            This operation is permanent and cannot be reversed. If you want to
            delete the world, type "delete" in the text field below, and click
            "Confirm".
          </p>
          <input class="ml-2 mt-3" v-model="securityText" />
        </section>
        <footer class="card-footer">
          <a class="card-footer-item" @click="confirm">Confirm</a>
          <a class="card-footer-item" @click="cancel">Cancel</a>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      modalActive: false,
      securityText: "",
      overlayActive: false,
    };
  },
  computed: {
    overlay() {
      /*
        Deleting the world may take a bit of time. 
        An overlay is added on top of the page to prevent the user from 
        doing anything while the world is being deleted.
      */
      const st = {
        position: "fixed" /* Sit on top of the page content */,
        display: "none" /* Hidden by default */,
        width: "100%" /* Full width (cover the whole page) */,
        height: "100%" /* Full height (cover the whole page) */,
        top: "0",
        left: "0",
        right: "0",
        bottom: "0",
        "background-color":
          "rgba(0,0,0,0.5)" /* Black background with opacity */,
        "z-index":
          "70" /* Specify a stack order in case you're using a different order for other elements */,
      };
      if (this.overlayActive) {
        st.display = "flex";
      }
      return st;
    },
  },
  methods: {
    cancel() {
      (this.securityText = ""), (this.modalActive = false);
    },
    async confirm() {
      if (this.securityText == "delete") {
        this.overlayActive = true;
        await this.$service.world.deleteOne(this.$world.id);
        this.$router.push({ name: "home" });
      }
    },
  },
};
</script>