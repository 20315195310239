import { reactive } from 'vue';

class Dashboard {

  constructor(fragment, { app, connection }) {
    this.app = app;
    this.connection = connection;

    if (fragment.content != 'Dashboard') {
      console.error('Dashboard must recieve a dashboard fragment as an argument');
      throw {}
    }

    this.data = reactive({
      id: fragment.id,
      name: fragment.name,
      props: fragment.props,
      handles: fragment.handles,
      content: fragment.content,
      createdAt: fragment.createdAt,
      updatedAt: fragment.updatedAt,
      dashboard: {
        text: ""
      }
    });

  }

  match({ id, handle }) {
    if (typeof id == 'number') {
      return this.data.id == id;
    } else {
      return handle && this.data.handles.indexOf(handle) != -1;
    }
  }

  async fetch() {
    const res = await this.connection.world.get(
      `/d/${this.data.id}`
    );

    this.data.name = res.data.name;
    this.data.props = res.data.props;
    this.data.handles = res.data.handles;
    this.data.updatedAt = res.data.updatedAt;
    this.data.dashboard.tempContent = res.data.dashboard.tempContent;
  }

  async update(data) {
    const res = await this.connection.world.put(
      `/d/${this.data.id}`, data
    );

    this.fetchedAt = Date.now();

    this.data.name = res.data.name;
    this.data.props = res.data.props;
    this.data.handles = res.data.handles;
    this.data.updatedAt = res.data.updatedAt;
    this.data.dashboard.tempContent = res.data.dashboard.tempContent;
  }

}

export default Dashboard;