
class FragmentService {

  constructor(mainframe) {
    this.mainframe = mainframe;
  }

  get(handle) {
    return this.mainframe.getWorld().fragments.getFragment({
      handle: handle
    });
  }
}

export default FragmentService;
