<template>
  <div class="card mt-2 is-clickable" @click="$emit('clicked', imagedata)">
    <div class="card-image">
      <figure class="image is-4by3">
        <hosted-image :handle="imagedata.handle" />
      </figure>
    </div>
    <div class="card-content">
      <div class="media">
        <div class="media-content">
          <p class="title is-4">{{ imagedata.name }}</p>
          <p class="subtitle is-6">{{ imagedata.handle }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "image-list-item",
  props: ["imagedata"],
};
</script>