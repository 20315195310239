
const placeholder = {
  id: -1,
  link: null,
}

const not_found = {
  id: -1,
  link: null,
}

class ResourceService {

  constructor(mainframe) {
    this.mainframe = mainframe;
  }

  getImage({ id, handle }) {
    if (this.mainframe.app.config.globalProperties.$store.hosts.length == 0) {
      return placeholder;
    }
    const res = this.mainframe.getWorld().resources.getImage({ id, handle });
    if (res) {
      return res;
    } else {
      return not_found;
    }
  }

  async fetchResource(id) {
    const resources = this.mainframe.getWorld().resources;
    await resources.fetch();
    return resources.list.find((r) => {
      return r.id == id;
    });
  }

  async uploadImage(data) {
    return await this.mainframe.getWorld().resources.uploadImage(data);
  }

  async updateImage(id, data) {
    return this.mainframe.getWorld().resources.updateImage(id, data);
  }

  async deleteImage(id) {
    return await this.mainframe.getWorld().resources.deleteImage(id);
  }


}

export default ResourceService;