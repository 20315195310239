<template>
  <div>
    <div class="columns">
      <div
        class="column is-one-third is-one-quarter-desktop is-one-fifth-fullhd"
      >
        <slot name="side"></slot>
      </div>
      <div class="column">
        <slot name="center"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
