<template>
  <div>
    <div class="field is-grouped">
      <div class="control is-expanded">
        <input
          class="input is-medium has-text-weight-bold"
          v-model="name"
          @input="registerChange"
        />
      </div>
      <div class="control is-expanded"></div>
      <div class="control">
        <button class="button is-medium" @click="showProps = !showProps">
          <span class="icon" v-if="showProps">
            <i class="fas fa-angle-double-right"></i>
          </span>
          <span class="icon" v-if="!showProps">
            <i class="fas fa-angle-double-left"></i>
          </span>
        </button>
      </div>
    </div>
    <div>
      <type-edit
        class="mb-2"
        ref="typeedit"
        :initialArticle="initialArticle"
        :types="types"
        @change="registerChange"
        @typesUpdate="generateKeyPrompts"
      />
      <handles-edit
        :initialArticle="initialArticle"
        class="mb-4"
        ref="handlesedit"
        @change="registerChange"
      />
    </div>
    <div class="columns is-multiline">
      <div class="column is-full-touch">
        <article-text-edit
          ref="textedit"
          :initialArticle="initialArticle"
          @change="registerChange"
        />
      </div>
      <div class="column is-full-touch" :style="propsStyle">
        <props-edit
          ref="propsedit"
          :initialArticle="initialArticle"
          @change="registerChange"
        />
      </div>
    </div>
    <br />
    <div v-if="errorMessage" class="mb-3 has-text-danger">
      {{ errorMessage }}
    </div>
    <a class="button mr-3" v-on:click="save">Save</a>
    <a class="button" v-on:click="discard">Discard</a>
    <confirmation-modal ref="confmodal" @confirm="discardConfirm">
      You have some un-saved modifications.
      <br />
      Are you sure you want to discard all changes ?
    </confirmation-modal>
    <confirmation-modal ref="safeguardmodal" @confirm="safeguardConfirm">
      Are you sure you want to leave the editor ?
      <br />
      All unsaved changes will be lost.
    </confirmation-modal>
  </div>
</template>

<script>
import ConfirmationModal from "@/components/templates/ConfirmationModal.vue";
import ArticleTextEdit from "./article-edit/ArticleTextEdit.vue";
import PropsEdit from "./article-edit/PropsEdit.vue";
import TypeEdit from "./article-edit/TypeEdit.vue";
import HandlesEdit from "./article-edit/HandlesEdit.vue";

export default {
  data() {
    return {
      create: true,
      initialArticle: null,
      showProps: true,
      name: "",
      unchanged: true,
      errorMessage: null,
    };
  },
  computed: {
    propsStyle() {
      if (this.showProps) {
        return {};
      } else {
        return {
          display: "none",
        };
      }
    },
    types() {
      return this.$store.types;
    },
  },
  methods: {
    async getEditData() {
      this.handle = this.$route.params.handle;
      try {
        const res = await this.$service.article.fetch(this.handle);
        this.initialArticle = res;
      } catch (err) {
        console.error(err);
      }
    },
    async getCreateData() {
      try {
        const emptyArticle = {
          article: {
            text: "",
          },
          handles: [],
          name: "New article",
          props: Object({}),
          types: [],
        };

        const typeId = this.$route.params.type;

        if (typeId != "none") {
          const type = this.$store.types.find((t) => t.id == typeId);
          if (type) {
            emptyArticle.name = "New " + type.name;
            emptyArticle.types.push({
              typeId: type.id,
            });
          }
        }

        this.initialArticle = emptyArticle;
      } catch (err) {
        console.error(err);
      }
    },
    generateKeyPrompts(myTypes) {
      // generate a list of all types from which the fragment will recieves keys

      const inheritedTypes = [];
      const typeList = this.types;
      function addType(t) {
        if (!inheritedTypes.find((type) => type.id == t.id)) {
          inheritedTypes.push(t);
          for (const btt of t.parents) {
            const parentType = typeList.find((type) => type.id == btt.parentId);
            if (parentType) {
              addType(parentType);
            }
          }
        }
      }
      for (const t of myTypes) {
        addType(t);
      }

      this.$refs.propsedit.recievePrompts(inheritedTypes);
    },
    registerChange() {
      if (this.unchanged) {
        this.unchanged = false;
        this.$safeguard.lock(() => {
          this.$refs.safeguardmodal.open();
          return false; // block all routes
        });
      }
    },
    async save() {
      const text = this.$refs.textedit.getText();
      const props = this.$refs.propsedit.getProps();
      const myTypes = this.$refs.typeedit.getMyTypes();
      const handles = this.$refs.handlesedit.getHandles();
      const data = {
        name: this.name,
        article: {
          text: text,
        },
        props: props,
        types: myTypes.map((t) => t.id),
        handles: handles,
      };

      if (this.create) {
        try {
          const response = await this.$service.article.create(data);
          this.$safeguard.clear();
          this.$emit("refresh");
          this.$router.push({
            name: "article",
            params: {
              handle: response.data.id,
            },
          });
        } catch (e) {
          console.error(e);
          this.errorMessage = e.message;
        }
      } else {
        try {
          await this.$service.article.update(this.initialArticle.id, data);
          this.$safeguard.clear();
          this.$emit("refresh");
          this.$router.push({ name: "article" });
        } catch (e) {
          console.error(e);
          this.errorMessage = e.message;
        }
      }
    },
    discard() {
      if (this.unchanged) {
        this.discardConfirm();
      } else {
        this.$refs.confmodal.open();
      }
    },
    async discardConfirm() {
      this.$safeguard.clear();
      this.confModalActive = false;
      if (this.$route.params.handle) {
        this.$router.push({ name: "article" });
      } else {
        this.$router.push({ name: "fragment-explorer" });
      }
    },
    safeguardConfirm() {
      this.$safeguard.continue();
    },
  },
  async mounted() {
    if (this.$route.name == "article-create") {
      this.create = true;
    } else {
      if (this.$route.name == "article-edit") {
        this.create = false;
      } else {
        console.error(`Unrecognized route name : ${this.$route.name}`);
        return;
      }
    }
    if (this.create) {
      await this.getCreateData();
    } else {
      await this.getEditData();
    }

    this.name = this.initialArticle.name;
    const myTypes = [];
    for (const btt of this.initialArticle.types) {
      const type = this.types.find((type) => type.id == btt.typeId);
      if (type) {
        myTypes.push(type);
      }
    }
    if (myTypes.length > 0) {
      this.generateKeyPrompts(myTypes);
    }
  },
  components: {
    ConfirmationModal,
    ArticleTextEdit,
    PropsEdit,
    TypeEdit,
    HandlesEdit,
  },
};
</script>,