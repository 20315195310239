<template>
  <div>
    <img v-if="image.url" :src="image.url" />
    <div v-else>Loading</div>
  </div>
</template>

<script>
export default {
  name: "hosted-image",
  props: ["handle"],
  computed: {
    image() {
      return this.$service.resource.getImage({ handle: this.handle });
    },
  },
};
</script>