<template>
  <side-template>
    <template #side>
      <fragment-side-panel ref="fsp" />
    </template>
    <template #center>
      <router-view @refresh="refresh" />
    </template>
  </side-template>
</template>

<script>
import SideTemplate from "@/components/templates/SideTemplate.vue";
import FragmentSidePanel from "./side-panel/FragmentSidePanel.vue";

export default {
  methods: {
    refresh() {
      this.$refs.fsp.refresh();
    },
  },
  components: {
    FragmentSidePanel,
    SideTemplate,
  },
};
</script>
