import Members from './members.js';
import Layout from './layout.js';

class Settings {

  constructor({ worldHandle, app, connection }) {

    this.worldHandle = worldHandle;
    this.app = app;
    this.connection = connection;

    this.members = new Members({ worldHandle, app, connection });
    this.layout = new Layout({ worldHandle, app, connection });

  }

  async fetch() {
    await this.members.fetch();
    await this.layout.fetch();

    return this;
  }

}

export default Settings;