const LEGAL_CHARS = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789_-".split(
  ""
);
const LETTERS = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ_-".split(
  ""
);
const NUMBERS = "0123456789".split("");

function verify(handle) {
  var containsLetter = false;
  for (let letter of handle) {
    if (LEGAL_CHARS.indexOf(letter) == -1) {
      return false;
    }
    if (LETTERS.indexOf(letter) != -1) {
      containsLetter = true;
    }
  }

  return containsLetter;
}

function isNumber(handle) {
  for (const c of handle) {
    if (NUMBERS.indexOf(c) == -1) {
      return false;
    }
  }
  return true;
}

function purify(handle) {

  // trim whitespaces at the begining and end of the handle
  const arr = handle.split('');
  const i1 = arr.findIndex((c) => (c != ' '));
  arr.reverse();
  const i2 = arr.findIndex((c) => (c != ' '));
  const trimmed = handle.substring(i1, arr.length - i2);

  const purifiedHandle = [];
  var containsLetter = false;

  for (const c of trimmed) {
    if (LEGAL_CHARS.indexOf(c) != -1) {
      purifiedHandle.push(c);
    }
    if (c == ' ') {
      purifiedHandle.push('-');
      containsLetter = true;
    }
    if (LETTERS.indexOf(c) != -1) {
      containsLetter = true;
    }
  }

  if (!containsLetter) {
    purifiedHandle.push('_');
  }

  return purifiedHandle.join('');
}

const errorMessage = "Handles can only contain letters numbers, and underscore. They must contain at least one non-number character";

export default {
  install(app) {
    app.config.globalProperties.$handles = {
      verify,
      purify,
      isNumber,
      errorMessage
    }
  }
}