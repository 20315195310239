<template>
  <div class="container is-max-widescreen">
    <h1 class="title">Welcome to Artefact</h1>
    <h2 class="subtitle">
      <em
        >Now in alpha 1.1. Check out the
        <router-link
          :to="{
            name: 'article',
            params: { worldHandle: 'devblog', handle: 'alpha-1-1' },
          }"
          >patch notes</router-link
        ></em
      >
    </h2>
    <a class="button" @click="createWorld">Create World</a>
    <div class="has-text-danger" v-if="createWorldWaring">
      You must be logged in to create a world
    </div>

    <!-- List -->
    <div class="tabs" v-if="$session.user.id">
      <ul>
        <li :class="{ 'is-active': activeTab == 'all' }">
          <a @click="activeTab = 'all'">All Worlds</a>
        </li>
        <li :class="{ 'is-active': activeTab == 'my' }">
          <a @click="activeTab = 'my'">My Worlds</a>
        </li>
      </ul>
    </div>
    <div class="mt-3" v-for="world in worldList" :key="world">
      <world-item :world="world" />
    </div>
  </div>
</template>

<script>
import WorldItem from "./WorldItem.vue";
export default {
  components: { WorldItem },
  name: "Home",
  data() {
    return {
      name: "",
      worlds: [],
      activeTab: "all",
      createWorldWaring: false,
    };
  },
  methods: {
    createWorld() {
      if (this.$session.user.id) {
        this.$router.push({ name: "create-world" });
      } else {
        this.createWorldWaring = true;
      }
    },
    async getWorlds() {
      try {
        var response = await this.$service.world.getAll();
        this.worlds = response.data;
      } catch (e) {
        //skip
      }
    },
  },
  computed: {
    worldList() {
      if (this.activeTab == "all" || !this.$session.user.id) {
        return this.worlds;
      }

      if (this.activeTab == "my") {
        return this.worlds.filter((w) => {
          return w.owner.id == this.$session.user.id;
        });
      }

      return [];
    },
  },
  mounted() {
    this.getWorlds();
  },
};
</script>
