<template>
  <div class="container is-max-widescreen">
    <h1 class="title">Create new world</h1>

    <div>
      <div class="field">
        <label class="label">Name</label>
        <div class="control has-icons-left">
          <input
            class="input"
            type="text"
            name="name"
            v-model="world.name"
            placeholder="name"
          />
          <span class="icon is-small is-left">
            <i class="fas fa-globe"></i>
          </span>
        </div>
      </div>

      <div class="field">
        <div class="label">
          <span class="icon-text">
            <span>URL</span>
            <help-box>
              This will be the URL of your world. If you do not want to share
              the world, you can leave a random value so that only people with
              the link can access it. However, if you intend to share this world
              publicly, it is better to use a readable url. The name of your
              world is a good choice.
            </help-box>
          </span>
        </div>
        <div class="field-body">
          <div class="field is-expanded">
            <div class="field has-addons">
              <p class="control">
                <a class="button is-static"> {{ origin }}/w/ </a>
              </p>
              <p class="control is-expanded">
                <input
                  :class="{
                    input: true,
                    'level-item': true,
                    'is-danger': !isValidHandle,
                  }"
                  type="text"
                  name="handle"
                  v-model="world.handle"
                  placeholder="worldurl"
                />
              </p>
            </div>

            <p class="help is-danger" v-if="!isValidHandle">
              URL can only contain letters, numbers and underscores. It must
              contain at least one letter.
            </p>
          </div>
        </div>
      </div>

      <div class="field">
        <label class="label">Description</label>
        <div class="control">
          <textarea
            class="textarea"
            v-model="world.description"
            placeholder="Awesome world with magical turnips"
            rows="3"
            cols="100"
          />
        </div>
      </div>

      <div class="field">
        <label class="label">Default Layout</label>
        <div class="control">
          <select name="default-layout" v-model="world.defaultLayout">
            <option value="none">None</option>
            <option v-for="dl in defaultLayoutList" :key="dl" :value="dl.value">
              {{ dl.name }}
            </option>
          </select>
        </div>
      </div>

      <div class="field">
        <label class="label">Visibility</label>
        <div class="control">
          <label class="checkbox">
            <input
              class="checkbox"
              type="checkbox"
              name="isPrivate"
              v-model="world.isPrivate"
            />
            <span class="icon-text ml-2">
              <span>Private</span>
              <span class="icon">
                <i class="fas fa-lock" />
              </span>
            </span>
          </label>
        </div>
      </div>

      <div class="field is-grouped">
        <div class="control">
          <button class="button is-link" @click="submit">Submit</button>
        </div>
        <div class="control">
          <button
            class="button is-link is-light"
            @click="$router.push({ name: 'home' })"
          >
            Cancel
          </button>
        </div>
      </div>

      <div
        class="has-text-danger"
        v-if="creationError"
        v-text="creationError"
      ></div>
    </div>
  </div>
</template>

<script>
import HelpBox from "./templates/HelpBox.vue";
export default {
  name: "CreateWorld",
  components: { HelpBox },
  data() {
    HelpBox;
    return {
      world: {
        name: "",
        handle: "",
        description: "",
        isPrivate: false,
        defaultLayout: "none",
      },

      creationError: "",

      defaultLayoutList: [
        {
          name: "Simple World",
          value: "simpleworld",
        },
        {
          name: "Simple RPG",
          value: "simplerpg",
        },
      ],
    };
  },
  computed: {
    isValidHandle() {
      return this.$handles.verify(this.world.handle);
    },
    origin() {
      const url = new URL(window.location.href);
      return url.origin;
    },
  },
  methods: {
    async submit() {
      if (!this.world.name) {
        this.creationError = "You must choose a name";
        return;
      }

      if (!this.world.handle) {
        this.creationError = "You must choose a url";
        return;
      }

      if (!this.isValidHandle) {
        this.creationError = "Please choose a valid url";
        return;
      }

      try {
        const data = {
          name: this.world.name,
          handle: this.world.handle,
          description: this.world.description,
          isPrivate: this.world.isPrivate,
          defaultLayout: this.world.defaultLayout,
        };

        const response = await this.$service.world.create(data);

        const world = response.data;

        this.$router.push({
          name: "world-home",
          params: { worldHandle: world.handle },
        });
      } catch (err) {
        if (err.message) {
          this.creationError = err.message;
        } else {
          this.creationError = "An unknow error occured";
        }
      }
    },
  },
  created() {
    this.world.handle = Math.random().toString(36).substr(2, 10);
  },
};
</script>
