<template>
  <div id="logdisplay">
    <div class="columns">
      <div class="column is-three-quarters"></div>
      <div class="column is-one-quarter">
        <div
          v-for="notif in $log.display"
          v-bind:key="notif"
          class="notification is-fluid"
          v-bind:class="{
            'is-warning': isWarning(notif),
            'is-danger': isError(notif),
          }"
        >
          <button class="delete" v-on:click="$log.clear(notif)"></button>
          {{ notif.message }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    display() {
      return this.$log.display.display;
    },
  },
  methods: {
    isWarning(notif) {
      return notif.type == "warning";
    },
    isError(notif) {
      return notif.type == "error";
    },
  },
};
</script>

<style scoped>
#logdisplay {
  position: absolute;
  bottom: 15px;
  left: 10px;
  right: 10px;
  z-index: 40;
  pointer-events: none;
}

.notification {
  pointer-events: auto;
}
</style>