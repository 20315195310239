<template>
  <span>
    <span style="cursor: pointer" class="icon">
      <a class="icon level-item" @click="open">
        <i class="fas fa-question-circle"></i>
      </a>
    </span>
    <div class="modal" :class="{ 'is-active': active }">
      <div class="modal-background" @click="close"></div>
      <div class="modal-card card">
        <section class="modal-card-body has-text-weight-normal">
          <slot></slot>
        </section>
      </div>
    </div>
  </span>
</template>

<script>
export default {
  name: "help-box",
  data() {
    return {
      active: false,
    };
  },
  methods: {
    open() {
      this.active = true;
      document.addEventListener("keydown", this.close);
    },
    close() {
      this.active = false;
      document.removeEventListener("keydown", this.close);
    },
  },
};
</script>
