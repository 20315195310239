import Connection from "./connection/connection";
import World from "./world/world";

class Mainframe {

  constructor(app) {
    this.app = app;
    this.connection = new Connection(app);
    this.handle = null;
    this.world = null;
  }

  async enterWorld(handle) {
    if (this.handle == handle) {
      this.world.get();
      return true;
    } else {
      this.connection.enterWorld(handle);
      if (this.world && this.world.handle == handle) {
        this.handle = handle;
        this.world.get();
        return true;
      } else {
        this.handle = handle;
        this.world = new World({
          handle: handle,
          app: this.app,
          connection: this.connection,
        });
        return await this.world.fetch();
      }
    }
  }

  exitWorld() {
    this.handle = null;
  }

  getWorld() {
    if (this.handle) {
      return this.world.get();
    } else {
      console.error('An attempt was made to access world while world is undefined');
      throw {}
    }
  }

  async fetchWorld() {
    if (this.handle) {
      return await this.world.fetch();
    } else {
      console.error('An attempt was made to fetch world while world is undefined');
      throw {}
    }
  }

  forceRefresh() {
    if (this.world) {
      this.world.fetchedAt = null;
    }
  }

}

export default Mainframe;