<template>
  <div id="app">
    <Navbar />

    <div class="m-5">
      <router-view />
    </div>
    <LogDisplay />
  </div>
</template>

<script>
import Navbar from "./components/Navbar";
import LogDisplay from "./components/LogDisplay";

export default {
  name: "App",
  components: {
    Navbar,
    LogDisplay,
  },
  created() {
    this.$service.auth.refresh();
  },
};
</script>
