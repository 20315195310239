<template>
  <div class="modal" :class="{ 'is-active': active }">
    <div class="modal-background" @click="close"></div>
    <div class="modal-card card">
      <header class="modal-card-head">
        <h1 v-if="askDelete" class="modal-card-title">
          Are you sure you want to delete image "{{ name }}" ?
        </h1>
        <h1 v-else-if="$world.canWrite" class="modal-card-title">
          <div class="field has-addons mr-5">
            <p class="control is-expanded">
              <input class="input" v-model="name" />
            </p>
            <p class="control">
              <button class="button" @click="askDelete = true">
                <span class="icon is-small is-right">
                  <i class="fas fa-trash-alt" />
                </span>
              </button>
            </p>
          </div>
          <!-- <input class="title" v-if="$world.canWrite" v-model="name" />

          <button class="button mr-3" @click="askDelete = true">
            <span class="icon">
              <i class="fas fa-trash-alt" />
            </span>
          </button> -->
        </h1>
        <h1 v-else class="modal-card-title">
          {{ name }}
        </h1>
        <button class="delete" @click="close"></button>
      </header>
      <section clas="modal-card-body">
        <p class="image">
          <hosted-image :handle="imageData?.handle" />
        </p>
        <p class="has-text-danger ml-4 mt-4" v-if="errorMessage">
          {{ errorMessage }}
        </p>
      </section>
      <footer class="modal-card-foot">
        <span v-if="askDelete">
          <div class="level">
            <div class="level-left"></div>
            <div class="level-right">
              <a class="level-item button" @click="confirmDelete">Delete</a>
              <a class="level-item button" @click="askDelete = false">Cancel</a>
            </div>
          </div>
        </span>
        <span v-else-if="$world.canWrite" class="is-flex is-flex-grow-1">
          <div class="field is-grouped is-flex-grow-1">
            <p class="control">
              <input class="input" v-model="handle" />
            </p>
            <p class="control is-flex-grow-1"></p>
            <p class="control" v-if="hasChanged">
              <button class="button" @click="confirm">Confirm</button>
            </p>
          </div>
        </span>
        <span v-else>
          <p>{{ handle }}</p>
        </span>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: "image-modal",
  data() {
    return {
      name: "",
      handle: "",
      imageData: null,

      active: false,
      askDelete: false,
      errorMessage: null,
    };
  },
  computed: {
    hasChanged() {
      return (
        this.name != this.imageData?.name ||
        this.handle != this.imageData?.handle
      );
    },
  },
  methods: {
    open(data) {
      this.name = data.name;
      this.handle = data.handle;
      this.imageData = data;
      this.askDelete = false;
      this.active = true;
    },
    close() {
      this.active = false;
    },
    async confirm() {
      if (!this.$handles.verify(this.handle)) {
        this.errorMessage = this.$handles.errorMessage;
        return;
      }

      const id = this.imageData.id;
      const data = {
        name: this.name,
        handle: this.handle,
      };
      try {
        await this.$service.resource.updateImage(id, data);
        this.active = false;
        this.$emit("change");
      } catch (err) {
        this.errorMessage = err.message;
      }
    },
    async confirmDelete() {
      try {
        await this.$service.resource.deleteImage(this.imageData.id);
        this.active = false;
        this.$emit("change");
      } catch (err) {
        this.errorMessage = err.message;
      }
    },
  },
};
</script>