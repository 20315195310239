import { reactive } from 'vue'

import Mainframe from './mainframe.js';

import Article from './api/article.api.js';
import Auth from './api/auth.api.js';
import Dashboard from './api/dashboard.api.js';
import Debug from './api/debug.api.js';
import Fragment from './api/fragment.api.js';
import Resource from './api/resource.api.js';
import Type from './api/type.api.js';
import User from './api/user.api.js';
import World from './api/world.api.js';

const world_fields = [
  'id',
  'handle',
  'name',
  'description',
  'isPrivate',
  'ownerId',
  'createdAt',
  'updatedAt',
  'settingsUpdatedAt',
  'fragmentsUpdatedAt',
  'writers',
  'viewers',
  'isOwner',
  'isWriter',
  'canWrite',
]

export default {
  install(app) {

    const mainframe = new Mainframe(app);

    const store = new Object({
      updateKey: 0,
      world: {},
      fragments: [],
      types: [],
      resources: [],
      hosts: [],
    });

    const service = {
      article: new Article(mainframe),
      auth: new Auth(mainframe),
      dashboard: new Dashboard(mainframe),
      debug: new Debug(mainframe),
      fragment: new Fragment(mainframe),
      resource: new Resource(mainframe),
      type: new Type(mainframe),
      user: new User(mainframe),
      world: new World(mainframe),
    };

    const world = {
      update(data) {
        for (const f of world_fields) {
          this[f] = data[f];
        }
      }
    }

    app.config.globalProperties.$store = reactive(store);
    app.config.globalProperties.$service = service;
    app.config.globalProperties.$world = reactive(world);

    app.config.globalProperties.$mainframe = mainframe;

  }
}