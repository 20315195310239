class AuthService {

  constructor(mainframe) {
    this.mainframe = mainframe;
    this.connection = mainframe.connection;
    this.session = mainframe.app.config.globalProperties.$session;
  }

  async login(data) {
    const response = await this.connection.root.post('/a/login', data);
    this.session.setUser(response.data.user);
    this.mainframe.forceRefresh();
  }

  register(data) {
    return this.connection.root.post('/a/register', data);
  }

  async logout() {
    await this.connection.root.post('/a/logout', {});
    this.session.clearUser();
    const url = new URL(window.location.href);
    window.location.href = url.origin;
    // force app to go to home page and reload upon loggin out.
    // too many things depend on who the user is when loaded. This is the easiest way to make sure they all reload.
  }

  async refresh() {

    const expires = new Date(this.session.user.expires);
    if (expires < Date.now()) {
      this.session.clearUser();
    }

    const response = await this.connection.root.post('/a/refresh', {
      user: this.session.user
    });

    if (response.data.logout) {
      this.session.clearUser();
    } else {
      if (response.data.user) {
        this.session.refresh(response.data.user);
      }
    }

  }

}

export default AuthService;
