class TypeService {

  constructor(mainframe) {
    this.mainframe = mainframe;
  }

  async fetch(id) {
    // TODO : improve
    await this.mainframe.getWorld().settings.layout.fetch();
    const types = this.mainframe.getWorld().settings.layout.types;
    return types.find((t) => (t.id == id));
  }

  async create(data) {
    return await this.mainframe.getWorld()
      .settings.layout.createType(data);
  }

  async update(id, data) {
    return await this.mainframe.getWorld()
      .settings.layout.updateType(id, data);
  }

  async updateParenthood(data) {
    return await this.mainframe.getWorld()
      .settings.layout.updateParenthood(data);
  }

  async deleteOne(id) {
    return await this.mainframe.getWorld()
      .settings.layout.deleteType(id);
  }
}

export default TypeService;
