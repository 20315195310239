import Worldapp from "@/components/worldapp/Worldapp";
import Home from "@/components/Home";
import Login from "@/components/Login";
import Register from "@/components/Register";
import CreateWorld from "@/components/CreateWorld";
import NotFound from "@/components/NotFound";
import Workshop from "@/components/Workshop";

import worldappRoutes from './worldapp.routes';

export default [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/login",
    name: "login",
    component: Login
  },
  {
    path: "/register",
    name: "register",
    component: Register
  },
  {
    path: "/create-world",
    name: "create-world",
    component: CreateWorld
  },
  {
    path: "/w/:worldHandle",
    name: "worldapp",
    component: Worldapp,
    props: true,
    children: worldappRoutes
  },
  {
    path: "/workshop",
    name: "workshop",
    component: Workshop,
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: NotFound
  },
];
