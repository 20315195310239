export default {
  install(app) {

    const $safeguard = {
      callback: null,
      to: null,
      lock(callback) {
        this.callback = callback;
      },
      clear() {
        this.callback = null;
      },
      continue() {
        this.callback = null;
        app.config.globalProperties.$router.push(this.to);
      },
      request(to) {
        if (this.callback) {
          this.to = to;
          return this.callback(to);
        } else {
          return true;
        }
      }
    }

    app.config.globalProperties.$safeguard = $safeguard;

  }
}