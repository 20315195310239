<template>
  <router-link
    :to="{ name: 'world-home', params: { worldHandle: world.handle } }"
    class="box"
  >
    <h1 class="title">{{ world.name }}</h1>
    <h2 class="subtitle">
      By <em>{{ world.owner.username }}</em>
    </h2>
    <div>
      {{ world.description }}
    </div>
  </router-link>
</template>

<script>
export default {
  name: "world-item",
  props: ["world"],
};
</script>