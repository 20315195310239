<template>
  <nav class="navbar is-primary" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
      <router-link class="navbar-item" :to="{ name: 'home' }">
        Artefact
      </router-link>
      <div v-if="$world.id" class="navbar-item has-dropdown is-hoverable">
        <router-link
          class="navbar-link"
          :to="{ name: 'world-home', params: { worldHandle: $world.handle } }"
        >
          {{ $world.name }}
        </router-link>
        <div class="navbar-dropdown">
          <!-- <a class="navbar-item"> Settings </a> -->
          <router-link
            class="navbar-item"
            :to="{
              name: 'fragment-explorer',
              params: { worldHandle: $world.handle },
            }"
          >
            Articles
          </router-link>
          <router-link
            class="navbar-item"
            :to="{
              name: 'image-explorer',
              params: { worldHandle: $world.handle },
            }"
          >
            Images
          </router-link>
          <router-link
            class="navbar-item"
            v-if="$world.isOwner"
            :to="{
              name: 'world-settings',
              params: { worldHandle: $world.handle },
            }"
          >
            Settings
          </router-link>
        </div>
      </div>
    </div>
    <div class="navbar-menu is-active">
      <div class="navbar-end">
        <div v-if="user.id" class="navbar-item has-dropdown is-hoverable">
          <a class="navbar-link">
            {{ user.username }}
          </a>

          <div class="navbar-dropdown">
            <!-- <a class="navbar-item"> Settings </a> -->
            <hr class="navbar-divider" />
            <a class="navbar-item" v-on:click="logout"> Logout </a>
          </div>
        </div>

        <router-link
          v-if="!user.id"
          class="navbar-item"
          :to="{ name: 'login' }"
        >
          Login
        </router-link>
        <router-link
          v-if="!user.id"
          class="navbar-item"
          :to="{ name: 'register' }"
        >
          Register
        </router-link>
        <div v-if="isDev" class="navbar-item has-dropdown is-hoverable">
          <a class="navbar-link"> DEBUG </a>

          <div class="navbar-dropdown">
            <a
              v-for="util in utils"
              class="navbar-item"
              :key="util"
              @click="utilClicked(util)"
            >
              {{ util }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      isDev: process.env.NODE_ENV === "development",
    };
  },
  computed: {
    user() {
      return this.$session.user;
    },
    utils() {
      const utils = [];
      for (const debug of this.$service.debug.utils) {
        utils.push(debug);
      }
      return utils;
    },
  },
  methods: {
    async utilClicked(util) {
      const res = await this.$service.debug[util](this);
      console.error(res);
    },
    async logout() {
      try {
        await this.$service.auth.logout();
      } catch (e) {
        //skip
      }
    },
  },
};
</script>
