<template>
  <div>
    <div class="field" v-for="field of fields" :key="field.id">
      <prop-line
        v-if="!field.isSection"
        v-model:name="field.key"
        v-model:value="field.value"
        :up="!isTop(field)"
        :down="!isBottom(field)"
        @update="$emit('change')"
        @click="click($event, field.id)"
      />
      <section-line
        v-else
        v-model:name="field.name"
        :up="!isTop(field)"
        :down="!isBottom(field)"
        @update="$emit('change')"
        @click="click($event, field.id)"
      />
    </div>
    <br />
    <a class="button mr-3" v-on:click="createProp">Create Prop</a>
    <a class="button mr-3" v-on:click="createSection">Create Section</a>
    <br />
  </div>
</template>

<script>
import PropLine from "./props-edit/PropLine.vue";
import SectionLine from "./props-edit/SectionLine.vue";

export default {
  data() {
    return {
      fields: [
        // 0: {
        //   id: 0,
        //   isSection : false,
        //   key: "",
        //   value: "",
        // },
      ],
      nextId: 0,
    };
  },
  props: {
    initialArticle: Object,
  },
  watch: {
    initialArticle(newValue) {
      if (newValue) {
        this.buildPropsModel(newValue.props);
      }
    },
  },
  methods: {
    isTop(field) {
      return this.fields[0] === field;
    },
    isBottom(field) {
      return this.fields[this.fields.length - 1] === field;
    },
    uniqueId() {
      const id = this.nextId;
      this.nextId++;
      return id;
    },
    indexOf(id) {
      for (var i = 0; i < this.fields.length; i++) {
        if (this.fields[i].id == id) {
          return i;
        }
      }
      return undefined;
    },
    recievePrompts(inheritedTypes) {
      for (const type of inheritedTypes) {
        const section = this.fields.find(
          (entry) => entry.isSection && entry.name == type.name
        );
        var i;
        if (section) {
          i = this.fields.indexOf(section) + 1;
        } else {
          const newSection = Object({
            id: this.uniqueId(),
            isSection: true,
            name: type.name,
          });
          this.fields.push(newSection);
          i = this.fields.length;
        }
        for (const k of type.keys) {
          const current = this.fields.find(
            (entry) => !entry.isSection && entry.key == k
          );
          if (!current) {
            const newProp = Object({
              id: this.uniqueId(),
              isSection: false,
              key: k,
              value: "",
            });
            this.fields.splice(i, 0, newProp);
            i++;
          }
        }
      }
    },
    getProps() {
      const props = {};
      var index = 0;
      var section = "";
      for (const entry of this.fields) {
        if (entry.isSection) {
          section = entry.name;
        } else {
          // Keys which don't contain a non-space character are ignored
          if (entry.key.split("").filter((c) => c != " ").length > 0) {
            props[entry.key] = {
              value: entry.value,
              section: section,
              index: index,
            };
            index++;
          }
        }
      }
      return props;
    },
    buildPropsModel(props) {
      const rawSections = {};

      for (const key in props) {
        const value = props[key];
        const section = value.section || "";
        if (!rawSections[section]) {
          rawSections[section] = {
            index: 0,
            name: section,
            content: [],
          };
        }
        rawSections[section].content.push({
          isSection: false,
          key: key,
          value: value.value,
        });
        rawSections[section].index = Math.max(
          rawSections[section].index,
          value.index
        );
      }
      const orderedSections = Object.values(rawSections);
      orderedSections.sort((e1, e2) => e2.index < e1.index);

      var fields = [];

      for (const section of orderedSections) {
        section.content.sort(
          (e1, e2) => props[e2.key].index < props[e1.key].index
        );

        const sectionId = this.uniqueId();
        fields.push(
          Object({
            id: sectionId,
            isSection: true,
            name: section.name,
          })
        );

        section.content.forEach((prop) => {
          const propId = this.uniqueId();
          prop.id = propId;
          fields.push(Object(prop));
        });
      }
      this.fields = fields;
    },
    click(event, id) {
      this.$emit("change");
      switch (event) {
        case "up":
          this.moveUp(id);
          return;
        case "down":
          this.moveDown(id);
          return;
        case "remove":
          this.remove(id);
          return;
      }
    },
    swap(indexA, indexB) {
      [this.fields[indexA], this.fields[indexB]] = [
        this.fields[indexB],
        this.fields[indexA],
      ];
    },
    moveUp(id) {
      const index = this.indexOf(id);
      this.swap(index - 1, index);
    },
    moveDown(id) {
      const index = this.indexOf(id);
      this.swap(index, index + 1);
    },
    remove(id) {
      const index = this.indexOf(id);
      this.fields.splice(index, 1);
    },
    createSection() {
      const newSection = Object({
        id: this.uniqueId(),
        isSection: true,
        name: "",
      });
      this.fields.push(newSection);
    },
    createProp() {
      const newProp = Object({
        id: this.uniqueId(),
        isSection: false,
        key: "",
        value: "",
      });
      this.fields.push(newProp);
    },
  },
  components: {
    PropLine,
    SectionLine,
  },
};
</script>
