<template>
  <div>
    <div :class="'control is-large' + (easyMDE !== null ? '' : ' is-loading')">
      <textarea
        class="textarea"
        id="article-editor"
        rows="10"
        cols="100"
        :readonly="easyMDE === null"
      />
    </div>
  </div>
</template>

<script>
import EasyMDE from "easymde";

export default {
  data() {
    return {
      easyMDE: null,
    };
  },
  methods: {
    init(initialText) {
      this.easyMDE = new EasyMDE({
        element: document.getElementById("article-editor"),
        initialValue: initialText,
        toolbar: [
          "bold",
          "italic",
          "strikethrough",
          "heading",
          "|",
          "quote",
          "unordered-list",
          "ordered-list",
          "|",
          "link",
          "image",
          "|",
          "guide",
        ],
        insertTexts: {
          image: ["![](", ")"],
          link: ["[", "]()"],
        },
      });
      this.easyMDE.codemirror.on("change", () => {
        this.$emit("change");
      });
    },
    getText() {
      return this.easyMDE.value();
    },
  },
};
</script>