import Article from "./article";
import Dashboard from "./dashboard";

class Fragment {
  constructor(data) {
    this.update(data);
    this.verify();
  }

  update(data) {
    this.id = data.id;
    this.name = data.name;
    this.props = data.props;
    this.handles = data.handles;
    this.content = data.content;
    this.types = data.types;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
  }

  verify() {
    if (typeof this.id != 'number') {
      throw 'id must be a number';
    }
  }
}


class Fragments {

  constructor({ app, connection }) {
    this.app = app;
    this.connection = connection;

    this.list = null;

    this.articles = [];
    this.dashboards = [];
  }

  updateStore() {
    this.app.config.globalProperties.$store.fragments = this.list;
    this.app.config.globalProperties.$store.updateKey += 1;
  }

  async fetch() {
    const res = await this.connection.world.get(`/f`);
    this.list = res.data.map((f) => new Fragment(f));

    this.updateStore();

    for (const fragment of this.list) {
      const article = this.articles.find((a) => a.match({ id: fragment.id }));
      if (article) {
        if (article.data.updatedAt != fragment.updatedAt) {
          article.fetch().catch(this.refreshError);
        }
        continue;
      }
      const dashboard = this.dashboards.find((d) => d.match({ id: fragment.id }));
      if (dashboard) {
        if (dashboard.data.updatedAt != fragment.updatedAt) {
          dashboard.fetch().catch(this.refreshError);
        }
        continue;
      }
    }
    return this.list;
  }

  accessArticle({ id, handle }) {
    const article = this.articles.find((a) => a.match({ id, handle }));
    if (article) {
      return article;
    }
    var fragment = this.list.find((fragment) => {
      if (fragment.content != 'Article') {
        return false
      }
      if (id) {
        return fragment.id == id
      } else {
        return handle && (fragment.handles.indexOf(handle) != -1)
      }
    });

    const new_article = new Article(fragment, {
      app: this.app,
      connection: this.connection
    });

    this.articles.push(new_article);

    return new_article;
  }


  accessDashboard({ id, handle }) {
    const dashboard = this.dashboards.find((d) => d.match({ id, handle }));
    if (dashboard) {
      return dashboard;
    }
    const fragment = this.list.find((fragment) => {
      if (fragment.content != 'Dashboard') {
        return false
      }
      if (id) {
        return fragment.id == id
      } else {
        return handle && (fragment.handles.indexOf(handle) != -1)
      }
    });

    const new_dashboard = new Dashboard(fragment, {
      app: this.app,
      connection: this.connection
    });

    this.dashboards.push(new_dashboard);

    return new_dashboard;
  }

  getArticle({ id, handle }) {
    const article = this.accessArticle({ id, handle });
    if (!article.fetchedAt) {
      article.fetch().catch(this.refreshError);
    }
    return article.data;
  }

  getDashboard({ id, handle }) {
    const dashboard = this.accessDashboard({ id, handle });
    if (!dashboard.fetchedAt) {
      dashboard.fetch().catch(this.refreshError);
    }
    return dashboard.data;
  }

  getFragment({ id, handle }) {
    const fragment = this.list.find((fragment) => {
      if (id) {
        return fragment.id == id
      } else {
        return handle && (fragment.handles.indexOf(handle) != -1)
      }
    });

    if (!fragment) {
      throw {
        message: "Fragment not found"
      };
    }

    if (fragment.content == 'Article') {
      return this.getArticle({ id, handle });
    }

    if (fragment.content == 'Dashboard') {
      return this.getDashboard({ id, handle });
    }

  }


  async fetchArticle(id) {
    const article = this.accessArticle({ id: id });
    await article.fetch();
    return article.data;
  }

  async createArticle(data) {
    const response = await this.connection.world.post('/a', data);

    this.list.push(new Fragment(response.data));
    const article = new Article(response.data, { app: this.app, connection: this.connection });
    this.articles.push(article);

    this.updateStore();

    return article;
  }

  async createDashboard(data) {
    const response = await this.connection.world.post('/d', data);

    this.list.push(new Fragment(response.data));
    const dashboard = new Dashboard(response.data);
    this.dashboards.push(dashboard);

    this.updateStore();

    return dashboard;
  }

  async updateArticle(id, data) {
    const article = this.accessArticle({ id: id });
    await article.update(data);
    const fragment = this.list.find((f) => f.id == id);
    fragment.update(article.data);

    this.updateStore();

    return article;
  }

  async updateDashboard(id, data) {
    const dashboard = this.accessDashboard({ id: id });
    await dashboard.update(data);
    const fragment = this.list.find((f) => f.id == id);
    fragment.update(dashboard.data);

    this.updateStore();

    return dashboard;
  }

  async deleteArticle(id) {
    await this.connection.world.delete(`/a/${id}`);
    const i = this.list.findIndex((f) => f.id == id);
    this.list.splice(i, 1);
    const j = this.articles.findIndex((a) => a.data.id == id);
    this.articles.splice(j, 1);

    this.updateStore();
  }


  refreshError(err) {
    console.error('Error encountered while refreshing fragment');
    console.error(err);
  }

}

export default Fragments;