import { reactive } from 'vue';

export default {
  install(app) {

    app.config.globalProperties.$log = {
      display: reactive([]),
      all: reactive([]),
      push(notif) {
        this.display.push(notif);
        this.all.push(notif);
      },
      clear(notif) {
        // careful : array changes have to be done in such a way that vue proxies can detect them
        const i = this.display.findIndex((e) => e == notif);
        this.display.splice(i, 1);
      }
    }

  }
}