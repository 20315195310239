<template>
  <div class="modal" :class="{ 'is-active': active }">
    <div class="modal-background" @click="cancel"></div>
    <div v-if="!confirmDelete" class="modal-card card">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ typeData.name }}</p>
      </header>
      <section class="modal-card-body">
        <div class="columns has-text-centered">
          <div class="column">
            <h1 class="subtitle has-text-grey-light">Properties</h1>
            <div class="mb-1 mt-1" v-for="(key, i) in typeData.keys" :key="i">
              {{ key }}
            </div>
          </div>
          <div class="column">
            <h1 class="subtitle has-text-grey-light">Parent Types</h1>
            <div v-for="type in parents" :key="type">
              {{ type.name }}
            </div>
          </div>
        </div>
      </section>
      <footer class="card-footer">
        <a class="card-footer-item" @click="deleteType">
          <span class="icon">
            <i class="fas fa-trash-alt"></i>
          </span>
          <span class="ml-1">Delete</span>
        </a>
        <a class="card-footer-item" @click="edit">
          <p class="icon is-left" @click="deleteType">
            <i class="fas fa-cog"></i>
          </p>
          <span class="ml-1">Edit</span>
        </a>
        <a class="card-footer-item" @click="cancel">Close</a>
      </footer>
    </div>
    <div v-if="confirmDelete" class="modal-card card">
      <section class="modal-card-body">
        Are you sure you want to delete the following type ?
        <br />
        {{ typeData.name }}
      </section>
      <footer class="card-footer">
        <a class="card-footer-item" @click="deleteConfirmed">Delete</a>
        <a class="card-footer-item" @click="deleteCanceled">Cancel</a>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: false,
      typeData: {},
      parents: [],
      confirmDelete: false,
    };
  },
  props: ["typeList"],
  methods: {
    open(typeData) {
      this.typeData = typeData;
      this.active = true;
      this.parents = typeData.parents.map((p) => {
        return this.typeList.find((t) => t.id == p.parentId);
      });
      this.confirmDelete = false;
    },
    cancel() {
      this.active = false;
    },
    edit() {
      this.active = false;
      this.$emit("edit");
    },
    deleteType() {
      this.confirmDelete = true;
    },
    deleteConfirmed() {
      this.active = false;
      this.$emit("delete");
    },
    deleteCanceled() {
      this.confirmDelete = false;
    },
  },
};
</script>