<template>
  <div class="container is-fluid">
    <h1 class="title">Members</h1>

    <div class="field">
      <label class="label">Owner</label>
      <div class="control has-icons-left">
        <input
          class="input"
          type="text"
          name="name"
          v-model="$session.user.username"
          placeholder="name"
          disabled
        />
        <!-- bit of a hack. only the owner can access this page, so I just user user info to display owner info.
        In the future, world member routes will be more convenient -->
        <span class="icon is-small is-left">
          <i class="fas fa-user"></i>
        </span>
      </div>
    </div>

    <div class="field">
      <label class="label">Writers</label>

      <field-list
        :elements="writers"
        :displayed="(e) => e.username"
        @add="addWriter"
        @remove="removeWriter"
      />
    </div>

    <div class="field">
      <label class="label">Viewers</label>

      <field-list
        :elements="viewers"
        :displayed="(e) => e.username"
        @add="addViewer"
        @remove="removeViewer"
      />
    </div>

    <user-select-modal
      :active="selectModalActive"
      :userList="userList"
      @cancel="selectCancel"
      @confirm="selectConfirm"
    />
  </div>
</template>

<script>
import FieldList from "../../templates/FieldList.vue";
import UserSelectModal from "./UserSelectModal.vue";

export default {
  name: "WorldMembers",
  data() {
    return {
      selectModalActive: false,
      userList: null,
      target: "",
      writers: [],
      viewers: [],
    };
  },
  methods: {
    async getData() {
      const id = this.$world.id;
      try {
        const response = await this.$service.world.getMembers(id);
        this.writers = response.data.writers;
        this.viewers = response.data.viewers;
      } catch (err) {
        console.error(err);
      }
    },
    async addWriter() {
      this.selectModalActive = true;
      this.target = "writers";
      const response = await this.$service.user.getAll();
      this.userList = response.data.filter(
        (u) =>
          u.id != this.$world.ownerId && !this.writers.find((w) => w.id == u.id)
      );
    },
    async addViewer() {
      this.selectModalActive = true;
      this.target = "viewers";
      const response = await this.$service.user.getAll();
      this.userList = response.data.filter(
        (u) =>
          u.id != this.$world.ownerId && !this.viewers.find((w) => w.id == u.id)
      );
    },
    async selectCancel() {
      this.selectModalActive = false;
      this.userList = null;
    },
    async selectConfirm(index) {
      const user = this.userList[index];
      try {
        if (this.target == "writers") {
          await this.$service.world.updateMembers(this.$world.id, {
            addWriters: [user],
          });
        }
        if (this.target == "viewers") {
          await this.$service.world.updateMembers(this.$world.id, {
            addViewers: [user],
          });
        }
      } catch (err) {
        //skip
      }
      this.selectModalActive = false;
      this.userList = null;
      this.getData();
    },
    async removeWriter(element) {
      try {
        await this.$service.world.updateMembers(this.$world.id, {
          removeWriters: [element],
        });
      } catch (err) {
        //skip
      }
      this.getData();
    },
    async removeViewer(element) {
      try {
        await this.$service.world.updateMembers(this.$world.id, {
          removeViewers: [element],
        });
      } catch (err) {
        //skip
      }
      this.getData();
    },
  },
  components: {
    UserSelectModal,
    FieldList,
  },
  mounted() {
    this.getData();
  },
};
</script>
