import { reactive, readonly } from 'vue';

export default {
  install(app) {

    var user;

    try {
      user = JSON.parse(localStorage.user);
      if (typeof user.username != 'string') {
        throw ({});
      }
      if (typeof user.id != 'number') {
        throw ({});
      }
      const expires = new Date(user.expires);
      if (expires < Date.now()) {
        throw ({});
      }
    } catch (err) {
      user = {
        id: null,
        username: null,
      };
    }

    const userdata = reactive(user);

    const session = {
      userdata: userdata,
      user: readonly(userdata),
      setUser(user) {
        localStorage.user = JSON.stringify({
          id: user.id,
          username: user.username,
          expires: user.expires
        });
        this.userdata.id = user.id;
        this.userdata.username = user.username;
        this.userdata.expires = user.expires;
      },
      refresh(user) {
        this.userdata.expires = user.expires;

      },
      clearUser() {
        delete localStorage.user;
        this.userdata.id = null;
        this.userdata.username = null;
      }
    }

    app.config.globalProperties.$session = session;

  }
}